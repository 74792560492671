import React, { useMemo } from "react";
import { appStateService } from "App";
import { useSelector } from "react-redux";
import { UserSelectors } from "redux/selectors";
import { EditIconButton } from "components/shared/button";
import { Button, ButtonVariant } from "components/shared/button";
import { translate } from "hooks/i18n"; // Adjust the import path as needed
import { FieldGroup, FieldGroupValue } from "./FieldGroupValue";

import "./UserProfileForm.scss";

/**
 * Contract for the properties of the UserProfileForm component.
 */
interface IUserProfileFormProps {
	/**
	 * Determines whether the modal is open or not.
	 */
	// open: boolean;
	// handleSave: (closeForm: boolean) => Promise<void>;
	// onEdit: () => void;
	onCancel: () => void;
	/**
	 *
	 *
	 * @param closeForm
	 * @returns
	 */
	onAfterSave: (closeForm: undefined | boolean) => void;
}

/**
 * UserProfileForm Component, which displays the user's profile information.
 * It is also editable by enabling the mode.
 *
 * @param props IUserProfileFormProps
 * @returns React.FC<IUserProfileFormProps>
 */

const UserProfileForm: React.FC<IUserProfileFormProps> = (props) => {
	const propNames = {
		userName: "name",
		userDisplayName: "displayName",
		userMail: "mail",
		isAdmin: "isAdmin"
	};
	const userProfileData = useSelector(UserSelectors.selectUserProfile);
	// const originalProfileData = useRef(userProfileData);
	const isEditing = useSelector(UserSelectors.selectUserProfileFormEditing);

	const texts = useMemo(
		() => ({
			formTitle: translate("userProfileForm.formTitle"),
			sectionTitle: translate("userProfileForm.sectionTitle"),
			propTitles: {
				userName: translate("userProfileForm.propTitles.userName"),
				userDisplayName: translate(
					"userProfileForm.propTitles.userDisplayName"
				),
				userMail: translate("userProfileForm.propTitles.userMail"),
				isAdmin: translate("userProfileForm.propTitles.isAdmin")
			},
			ariaLabels: {
				editUserName: translate(
					"userProfileForm.ariaLabels.editUserName"
				),
				editDisplayName: translate(
					"userProfileForm.ariaLabels.editDisplayName"
				)
			}
		}),
		[
			/* dependencies if any, e.g., current language */
		]
	);

	const fetchLatestUserData = async () => {
		// Replace with actual data fetching logic, e.g., API call or selector
		const latestData = await appStateService.user.getLatestProfile();
		return latestData;
	};

	const handleSave = () => {
		handleSaveChanges(true);
	};

	const handleSaveChanges = async (closeForm: boolean) => {
		try {
			const latestData = await fetchLatestUserData();
			const updatedData = {
				...latestData,
				name: userProfileData.name,
				displayName: userProfileData.displayName
				// mail: userProfileData.mail,
				// isAdmin: userProfileData.isAdmin
			};
			await appStateService.user.updateItem(updatedData);
			if (typeof props?.onAfterSave === "function") {
				props?.onAfterSave(closeForm);
			}
		} catch (error) {
			// Handle error (optional)
			console.error("Failed to save changes:", error);
		}
	};

	// const handleSave = () => {
	// 	handleSaveChanges(false);
	// };

	const handleEdit = () => {
		appStateService.user.setProfileFormEditing(true);
	};

	// const handleCancel = () => {
	// 	// Restores the original data to the form, if anything changed.
	// 	appStateService.user.setName(originalProfileData.current.name);
	// 	appStateService.user.setDisplayName(
	// 		originalProfileData.current.displayName
	// 	);
	// 	// appStateService.user.setEmail(originalProfileData.current.mail);

	// 	// Closes the form.
	// 	appStateService.user.setProfileFormEditing(false);
	// 	appStateService.user.setProfileFormOpen(false);
	// };

	return (
		<div className="userProfileForm">
			<div className="section">
				<h3 className="section_title">{texts.sectionTitle}</h3>
				<form onSubmit={() => {}}>
					<FieldGroup>
						<label htmlFor={propNames.userName}>
							{texts.propTitles.userName}
						</label>
						<div className="field-row">
							<FieldGroupValue
								id={propNames.userName}
								name={propNames.userName}
								isEditing={isEditing}
								setter={(prop, val) =>
									appStateService.user.setName(val.toString())
								}
							/>
							<EditIconButton
								onClick={handleEdit ?? undefined}
								aria-label={texts.ariaLabels.editUserName}
								disabled={isEditing}
							/>
						</div>
					</FieldGroup>
					<FieldGroup>
						<label htmlFor={propNames.userDisplayName}>
							{texts.propTitles.userDisplayName}
						</label>
						<div className="field-row">
							<FieldGroupValue
								id={propNames.userDisplayName}
								name={propNames.userDisplayName}
								isEditing={isEditing}
								setter={(prop, val) =>
									appStateService.user.setDisplayName(
										val.toString()
									)
								}
							/>
							<EditIconButton
								onClick={handleEdit ?? undefined}
								aria-label={texts.ariaLabels.editDisplayName}
								disabled={isEditing}
							/>
						</div>
					</FieldGroup>
					<FieldGroup>
						<label htmlFor={propNames.userMail}>
							{texts.propTitles.userMail}
						</label>
						<a href={`mailto:${userProfileData.mail}`}>
							{userProfileData.mail}
						</a>
					</FieldGroup>
					<FieldGroup>
						<label htmlFor={propNames.isAdmin}>
							{texts.propTitles.isAdmin}
						</label>
						{userProfileData?.roles?.admin ? "Sim" : "Não"}
					</FieldGroup>
				</form>
			</div>
			<div className="footer">
				{/* <Button
					text={"Cancelar"}
					onClick={props.onCancel}
					variant={ButtonVariant.Secondary}
				/> */}
				{/* {!isEditing ? (
					<Button
						text={"Editar Perfil"}
						onClick={handleEdit ?? undefined}
					/>
				) : (
					<Button text={"Salvar"} onClick={handleSave} />
				)} */}
			</div>
		</div>
	);
};

export { UserProfileForm, IUserProfileFormProps };
