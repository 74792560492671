import { appStateService, store } from "App";
import { IClient, IClientOrder, IProduct, ClientOrder } from "models/index";
import { actions } from "redux/reducers/orderForm/reducer";
import { CompanyOrderService } from "services/order/CompanyOrderService";
import { StoreType } from "types/StoreType";

/**
 * Class for managing the basics of the Order Form data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
export class OrderFormService {
	_store: StoreType;
	orderService: CompanyOrderService;

	constructor() {
		this._store = store;
		this.orderService = appStateService.company.order.get();
	}

	/**
	 * Sets the loading state of the Order Form.
	 *
	 * @param status The loading state.
	 */
	setLoading(status: boolean): void {
		this._store.dispatch(actions.setLoading(status));
	}

	/**
	 * Sets the queried state of the Order Form.
	 *
	 * @param status The queried state.
	 */
	setQueried(status: boolean): void {
		this._store.dispatch(actions.setQueried(status));
	}

	/**
	 * Sets the products list of the Order Form.
	 *
	 * @param products The products list.
	 */
	setProductsList(products: IProduct[]): void {
		this._store.dispatch(actions.setProductsList(products));
	}

	/**
	 * Sets the whole order form base on its domain model.
	 *
	 * @param orderForm The order form.
	 */
	setOrderForm(orderForm: IClientOrder): void {
		this._store.dispatch(actions.setOrderForm(orderForm));
	}

	/**
	 * Sets the adding product state of the Order Form.
	 *
	 * @param status The adding product state.
	 */
	setAddingProduct(status: boolean): void {
		this._store.dispatch(actions.setAddingProduct(status));
	}

	/**
	 * Sets the adding client state of the Order Form.
	 *
	 * @param status The adding client state.
	 */
	setAddingClient(status: boolean): void {
		this._store.dispatch(actions.setAddingClient(status));
	}

	/**
	 * Sets the saving client state of the Order Form.
	 *
	 * @param status The saving client state.
	 */
	setSavingClient(status: boolean): void {
		this._store.dispatch(actions.setSavingClient(status));
	}

	/**
	 * Sets the product search text of the Order Form.
	 *
	 * @param text The product search text.
	 */
	setProductSearchText(text: string): void {
		this._store.dispatch(actions.setProductSearchText(text));
	}

	/**
	 * Toggles the adding product state of the Order Form.
	 */
	toggleAddingProduct(): void {
		const addingProduct =
			this._store.getState().clientOrderForm.addingProduct;
		const stateIsAddingProduct = !!addingProduct;

		// If the current state is to be adding the product,
		// Also dispatches the product empty query.
		if (stateIsAddingProduct) {
			// TODO: Query products before hand
		}

		this._store.dispatch(actions.setAddingProduct(!addingProduct));
	}

	/**
	 * Adds a product to the order form.
	 *
	 * @param product The product to be added.
	 */
	setClientData(client: IClient): void {
		this._store.dispatch(actions.setClientData(client));
	}

	/**
	 * Removes a product from the order form.
	 *
	 * @param itemId The ID of product to be removed.
	 */
	removeOrderItem(itemId: string): void {
		this._store.dispatch(actions.removeOrderItem(itemId));
	}

	/**
	 * Adds a Client to the given Order entry (by ID).
	 *
	 * @param orderId The ID of the Order entry.
	 * @param client The Client to be added.
	 */
	async addClientToOrder(orderId: string, client: IClient): Promise<void> {
		await this.orderService
			.getItemById(orderId)
			.then(async (order) => {
				if (order) {
					order.clientId = client.id;
					order.name = client.name;
					order.email = client.mail;
					order.phone = client.phone;

					await this.orderService.updateItem(order);

					this._store.dispatch(actions.addClientToOrderForm(client));
				}
			})
			.catch((error) => {
				// TODO: Deal with possible errors
				if (process.env.NODE_ENV === "development")
					console.error(error);
			});
	}

	/**
	 * Cleans up and resets the Order Form state.
	 */
	reset(): void {
		this._store.dispatch(actions.reset());
	}

	/**
	 * Resets the Order Form state.
	 */
	async resetOrder() {
		this._store.dispatch(actions.resetOrder());
	}
}
