import React from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";

import { Routes } from "./Routes";

// Selectors and Services
import { UserSelectors } from "redux/selectors";
import { ComponentPaths } from "./ComponentPaths";
import { LazyLoad } from "./LazyLoad";
import { AuthenticationLayer } from "components/modules/AuthLayer.modules";

// Route module imports
import { OrderProductionModule } from "components/modules/OrderProduction.module";
import { TableServiceModule } from "components/modules/TableService.module";
import { StoreFrontModule } from "components/modules/StoreFront.module";
import { DashboardModule } from "components/modules/Dashboard.module";
import { BasicModule } from "components/modules/Basic.module";
import { StockManagementModule } from "components/modules/StockManagement.module";
import { OrderManagementModule } from "components/modules/OrderManagement.module";
import { AdminModule } from "components/modules/Admin.modules";
import { BrandsModule } from "components/modules/Brands.module";
import { OrderExternalModule } from "components/modules/OrderExternal.module";
import { SupplierManagementModule } from "components/modules/SupplierManagement.module";

/**
 * The routes for the list pages.
 */
const ListRoutes = [
	// Admin routes
	...AdminModule(),
	// Basic routes
	...BasicModule(),
	// Brands routes
	...BrandsModule(),
	// Stock Management routes
	...StockManagementModule(),
	// Supplier Management routes
	...SupplierManagementModule(),
	// Order Management routes
	...OrderManagementModule(),
	// Order Production routes
	...OrderProductionModule(),
	// Order External routes
	...OrderExternalModule(),
	// Store Routes
	...StoreFrontModule(),
	// Production in-house Servicing
	...TableServiceModule(),
	// Dashboard Routes
	...DashboardModule()
];

/**
 * The [Authenticated] Router object, which contains the routes and the elements to render.
 */
export const RouterConfig: RouteObject[] = [
	...ListRoutes,
	...AuthenticationLayer([
		{
			path: Routes.Home,
			element: LazyLoad(ComponentPaths.HomePage)
		}
	]),
	{
		path: Routes.Landing,
		element: LazyLoad(ComponentPaths.LandingPage)
	},
	{
		path: Routes.Error404,
		element: LazyLoad(ComponentPaths.Error404Page)
	},
	{
		path: Routes.All,
		element: <Navigate to={Routes.Home} replace />
	}
];

/**
 * The [Anonymous] Router object, which contains the routes and the elements to render.
 */
export const RouterAnon: RouteObject[] = [
	{
		path: Routes.Landing,
		element: LazyLoad(ComponentPaths.LandingPage)
	},
	{
		path: Routes.Login,
		element: LazyLoad(ComponentPaths.LoginPage)
	},
	{
		path: Routes.Error404,
		element: LazyLoad(ComponentPaths.Error404Page)
	},
	{
		path: Routes.LoginPasswdRecovery,
		element: LazyLoad(ComponentPaths.LostPasswordPage)
	},
	{
		path: Routes.SignUp,
		element: LazyLoad(ComponentPaths.SignUpPage)
	},
	{
		path: Routes.OnBoarding,
		element: LazyLoad(ComponentPaths.OnBoardingPage)
	},
	{
		path: Routes.All,
		element: <Navigate to={Routes.Landing} />
	}
];

/**
 * Renders pages based on the passed props.routePath.
 *
 * @param {*} param0 The Properties of the component
 * @returns JSX.Element
 */
function AppRouter(): JSX.Element {
	const isAuth = useSelector(UserSelectors.selectAuthenticated);
	const routes = useRoutes(isAuth ? RouterConfig : RouterAnon);

	return routes;
}

export { AppRouter };
