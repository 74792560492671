import { AppProfileNames } from "components/modules/profiles/AppProfileNames";
import { Timestamp } from "firebase/firestore";
import { IFirebaseItem } from "interfaces/index";
import { ISliceList } from "interfaces/slices";

/**
 * Contract for typing each Company information within the system.
 */
export interface ICompany extends IFirebaseItem {
	id: string;
	name: string;
	foundedOn: Timestamp;
	companyProfile: "" | AppProfileNames;
	active: boolean;
	ownerId: string;
	contactMail: string;
	contactName: string;
	contactPhone: string;
	contactWhatsApp: string;
}

/**
 * Company Redux slice interface.
 */
export interface ICompanySlice extends ISliceList<ICompany> {}

/**
 * System-level Company factory class.
 */
export class Company implements ICompany {
	id: string = "";
	name: string = "";
	foundedOn: Timestamp = Timestamp.now();
	timestamp: Timestamp = Timestamp.now();
	deleted: boolean;
	companyProfile: "" | AppProfileNames;
	active: boolean;
	ownerId: string;
	contactMail: string;
	contactName: string;
	contactPhone: string;
	contactWhatsApp: string;

	constructor(data?: undefined | ICompany) {
		this.id = data?.id ?? "";
		this.name = data?.name ?? "";
		this.foundedOn = data?.foundedOn ?? Timestamp.now();
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.deleted = data?.deleted ?? false;
		this.companyProfile = data?.companyProfile ?? AppProfileNames.appNone;
		this.active = data?.active ?? true;
		this.ownerId = data?.ownerId ?? "";
		this.contactMail = data?.contactMail ?? "";
		this.contactName = data?.contactName ?? "";
		this.contactPhone = data?.contactPhone ?? "";
		this.contactWhatsApp = data?.contactWhatsApp ?? "";
	}
}

/**
 * Companies initial state.
 */
export const companiesInitialState: ICompanySlice = {
	editing: false,
	editingList: [],
	list: [],
	loading: false,
	queried: false
	// error: ""
};
