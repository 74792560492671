import { appStateService, store } from "App";
import { AppModules } from "./AppModules";
import { ModuleActions } from "./actions";
import { AppProfiles } from "./profiles/AppProfiles";
import { CompanySelectors, UserSelectors } from "redux/selectors";

/**
 * Prepares and Configures the application modules,
 * Based on User Account access data.
 */
export async function configAppModules() {
	const userSvc = appStateService.user;
	// const companySvc = new CompanyAdminService
	const companySvc = appStateService.company.service.get();

	if (!appStateService.appManager.getProfileLoading()) {
		// appStateService.appManager.setLoading(true);
		appStateService.appManager.setProfileLoading(true);
	}

	// No login detected, no need to load profile anymore
	if (!userSvc.getUserId()) {
		AppModules.applyProfile([]);
		ModuleActions.applyProfile([]);
		appStateService.appManager.setProfileType(null);
		appStateService.appManager.setProfileLoading(false);
		appStateService.appManager.setProfileLoaded(true);
		appStateService.appManager.setLoading(false);
		return;
	}

	// Init user company data, then select it from state
	await userSvc.getLatestProfile();
	companySvc.ensureLoadedUserCompanies();

	const storeState = store.getState();
	const activeCompanyId =
		UserSelectors.selectUserProfileActiveCompany(storeState);
	// const companyList = CompanySelectors.getCompanyById(
	// 	storeState,
	// 	activeCompanyId
	// );
	// const companyAdminList =
	// 	CompanyAdminSelectors.getCompanyAdminList(storeState);

	const activeCompany = CompanySelectors.getCompanyById(
		storeState,
		activeCompanyId
	);
	// companyList.find(
	// 	(company) => company.id === activeCompanyId
	// );

	// const activeCompany = CompanyAdminSelectors.getCompanyAdminList(
	// 	storeState,
	// 	activeCompanyId
	// );

	if (!activeCompanyId || !activeCompany?.companyProfile) {
		// Enables the modules below according to the user profile
		AppModules.applyProfile([]);
		ModuleActions.applyProfile([]);
		appStateService.appManager.setProfileLoading(false);
		return;
	}

	const userCompanyProfileName = activeCompany.companyProfile;
	const userCompanyProfile = AppProfiles[userCompanyProfileName];

	// Enables the modules below according to the user profile
	AppModules.applyProfile(userCompanyProfile.appModules);
	ModuleActions.applyProfile(userCompanyProfile.moduleActions);

	// Sets the profile type and other configurations
	appStateService.appManager.setProfileType(userCompanyProfileName);
	appStateService.appManager.setProfileLoading(false);
	appStateService.appManager.setProfileLoaded(true);
	appStateService.appManager.setLoading(false);
}
