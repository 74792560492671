import { Routes } from "components/router/Routes";
import { ComponentPaths } from "components/router/ComponentPaths";
import { LazyLoad } from "components/router/LazyLoad";
import { RouteObject } from "react-router-dom";
import { AppModuleNames } from "./AppModules";
import { AuthLayerEnabledModule } from "./AuthLayerEnabled.module";

/**
 * The System Basic module routes.
 *
 * @returns The module routes.
 */
export const BasicModule: () => RouteObject[] = () => {
	return AuthLayerEnabledModule(AppModuleNames.basic, [
		{
			path: Routes.BeeAIChat(),
			element: LazyLoad(ComponentPaths.BeeAIChatPage)
		},
		{
			path: Routes.CategoriesList,
			element: LazyLoad(ComponentPaths.CategoriesPage)
		},
		{
			path: Routes.ClientsList,
			element: LazyLoad(ComponentPaths.ClientsPage)
		},
		{
			path: Routes.CompanyLinkList,
			element: LazyLoad(ComponentPaths.CompanyLinksList)
		},
		{
			path: Routes.ProductsList,
			element: LazyLoad(ComponentPaths.ProductsPage)
		},
		{
			path: Routes.SellOrderForm,
			element: LazyLoad(ComponentPaths.OrderInternalPage)
		}
	]);
};
