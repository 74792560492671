import { IComponentPaths } from "interfaces/index";

/**
 * The paths to the page components.
 * Used for Lazy loading the components.
 * Should be Updated whenever routes are changed/added.
 */
export const ComponentPaths: IComponentPaths = {
	BeeAIChatPage: "chat/OpenAIChatPage",
	CategoriesPage: "categories/CategoriesPage",
	BrandsPage: "brands/BrandsPage",
	ClientsPage: "clients/ClientsPage",
	ProductsPage: "products/ProductsPage",
	ProductStockPage: "productStock/ProductStockPage",
	ProductStockEditForm: "productStock/ProductStockEditForm",
	SuppliersPage: "suppliers/SuppliersPage",
	LandingMailsPage: "landingMails/LandingMailsPage",
	OrderInternalPage: "order/internal/OrderInternalPage",
	OrderProductionPage: "order/internal/OrderProductionPage",
	OrderProductionCarouselPage: "order/internal/OrderProductionCarouselPage",
	OrderManagementPage: "order/management/OrderManagementPage",
	HomePage: "home/HomePage",
	LoginPage: "login/LoginPage",
	LostPasswordPage: "login/lostPassword/LostPasswordPage",
	LandingPage: "landing/LandingPage",
	Error404Page: "error/Error404Page",
	SignUpPage: "signUp/SignUpPage",
	OnBoardingPage: "onBoarding/OnBoardingPage",
	ClientOrderList: "order/list/OrderListPage",
	ClientOrder: "order/internal/OrderInternalPage", // TODO: Change file ref to match properly ClientOrderPage
	ClientTables: "clientTables/ClientTablesPage",
	ExternalOrder: "order/external/OrderExternalPage",
	StoreHome: "store/StorePage",
	StoreItemView: "store/StoreItemViewPage",
	StockMovementsListPage: "stockMovement/StockMovementPage",
	CompanyLinksList: "companyLinks/CompanyLinksPage",
	DashboardGraphDetail: "dashboard/graph/GraphDetailsPage",
	CompanyAdminListPage: "companies/CompanyAdminListPage",
	MailerAdminMainPage: "admin/mailer/MailerMainPage"
};
