import React from "react";
import { Dropdown } from "components/shared/dropdown";
import { IDropdownOption } from "@fluentui/react";

/**
 * Contract for the properties provided to the DropdownGroup component.
 */
export interface IDropdownGroupProps {
	id: string;
	label: string;
	options: IDropdownOption[];
	value: string | string[];
	disabled?: undefined | boolean;
	hideEmptyOption?: undefined | boolean;
	multi?: undefined | boolean;
	className?: undefined | string;
	required?: undefined | boolean;
	onChange: (event: any, option: any) => void;
}

/**
 * DropdownGroup shared component.
 *
 * @param props IDropdownGroupProps
 * @returns React.FC<IDropdownGroupProps>
 */
export const DropdownGroup: React.FC<IDropdownGroupProps> = (props) => {
	return (
		<div className="dropdown-group">
			<label htmlFor={props.id}>{props.label}</label>
			<Dropdown
				id={props.id}
				options={props.options}
				selectedKey={props.value}
				onChange={props.onChange}
				disabled={props.disabled}
				hideEmptyOption={props.hideEmptyOption}
				listName={props.label}
				multi={props?.multi ?? false}
				className={props?.className ?? ""}
				required={props?.required ?? false}
			/>
		</div>
	);
};
