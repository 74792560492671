import React from "react";
import { ICompanyForm } from "models/index";
import { FormContainer } from "../../FormContainer";
import { useSelector } from "react-redux";
import { CompanySelectors, UserSelectors } from "redux/selectors";
import { DatePickerGroup, InputGroup } from "components/shared/input";
import { RootState } from "redux/reducers";
import { useTranslateFn } from "hooks";

/**
 * User Company Form Props contract.
 */
export interface IUserCompanyFormProps {}

/**
 * User Company Editing Form.
 *
 * @param props
 * @returns React.FC
 */
export const UserCompanyForm: React.FC<IUserCompanyFormProps> = (props) => {
	const translate = useTranslateFn();

	const activeCompanyId: string = useSelector(
		UserSelectors.selectUserProfileActiveCompany
	);
	const activeCompanyData: ICompanyForm = useSelector((state) =>
		CompanySelectors.getCompanyFormById(state as RootState, activeCompanyId)
	);

	// Define and preload translated strings
	const strings = {
		labels: {
			companyName: translate("userCompanyForm.labels.companyName"),
			foundedOn: translate("userCompanyForm.labels.foundedOn"),
			contactMail: translate("userCompanyForm.labels.contactMail"),
			contactPhone: translate("userCompanyForm.labels.contactPhone"),
			contactWhatsApp: translate("userCompanyForm.labels.contactWhatsApp")
		},
		placeholders: {
			companyName: translate("userCompanyForm.placeholders.companyName"),
			foundedOn: translate("userCompanyForm.placeholders.foundedOn"),
			contactMail: translate("userCompanyForm.placeholders.contactMail"),
			contactPhone: translate(
				"userCompanyForm.placeholders.contactPhone"
			),
			contactWhatsApp: translate(
				"userCompanyForm.placeholders.contactWhatsApp"
			)
		}
	};

	return (
		<FormContainer sideForm>
			<InputGroup
				id={"companyName"}
				label={strings.labels.companyName}
				value={activeCompanyData.name}
				placeholder={strings.placeholders.companyName}
				disabled
			/>
			<DatePickerGroup
				id={"foundedOn"}
				label={strings.labels.foundedOn}
				value={activeCompanyData?.foundedOn?.toDate() ?? null}
				placeholder={strings.placeholders.foundedOn}
				disabled
			/>
			<InputGroup
				id={"contactMail"}
				label={strings.labels.contactMail}
				value={activeCompanyData.contactMail}
				placeholder={strings.placeholders.contactMail}
				disabled
			/>
			<InputGroup
				id={"contactPhone"}
				label={strings.labels.contactPhone}
				value={activeCompanyData.contactPhone}
				placeholder={strings.placeholders.contactPhone}
				disabled
			/>
			<InputGroup
				id={"contactWhatsApp"}
				label={strings.labels.contactWhatsApp}
				value={activeCompanyData.contactWhatsApp}
				placeholder={strings.placeholders.contactWhatsApp}
				disabled
			/>
		</FormContainer>
	);
};
