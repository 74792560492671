import { ISliceList } from "interfaces/slices";
import { Company, ICompany } from "./Company";

/**
 * Contract for handling each individual Company, as clients in the system.
 * Information within the system is protected and secured.
 * No further detail is given upon companies and their info
 */
export interface ICompanyAdminSlice extends ISliceList<ICompany> {
	modalOpen: boolean;
	formData: ICompany;
}

/**
 * Company Admin initial state.
 */
export const companyAdminInitialState: ICompanyAdminSlice = {
	editing: false,
	editingList: [],
	list: [],
	loading: false,
	queried: false,
	modalOpen: false,
	formData: new Company()
	// error: ""
};
