import { Routes } from "components/router/Routes";
import { ComponentPaths } from "components/router/ComponentPaths";
import { LazyLoad } from "components/router/LazyLoad";
import { RouteObject } from "react-router-dom";
import { AppModuleNames } from "./AppModules";
import { AuthLayerEnabledModule } from "./AuthLayerEnabled.module";

/**
 * The system Brands module routes.
 *
 * @returns The module routes.
 */
export const BrandsModule: () => RouteObject[] = () => {
	return AuthLayerEnabledModule(AppModuleNames.dashboard, [
		{
			path: Routes.BrandsList,
			element: LazyLoad(ComponentPaths.BrandsPage)
		}
	]);
};
