import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getApp } from "firebase/app";

/**
 * Service to handle reCAPTCHA integration with Firebase.
 */
class ReCaptchaService {
	private static instance: ReCaptchaService;
	private initialized: boolean = false;

	private constructor() {
		// Private constructor to enforce singleton
	}

	/**
	 * Gets the singleton instance of ReCaptchaService.
	 */
	public static getInstance(): ReCaptchaService {
		if (!ReCaptchaService.instance) {
			ReCaptchaService.instance = new ReCaptchaService();
		}
		return ReCaptchaService.instance;
	}

	/**
	 * Initializes reCAPTCHA with Firebase App Check.
	 * @param siteKey - The reCAPTCHA site key
	 * @param debug - Whether to enable debug mode (default: false)
	 */
	public initialize(siteKey: string, debug: boolean = false): void {
		if (this.initialized) return;

		try {
			if (debug) {
				// Enable debug mode for development
				// @ts-ignore - This is a valid property for development
				this.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
			}

			const app = getApp();
			initializeAppCheck(app, {
				provider: new ReCaptchaV3Provider(siteKey),
				isTokenAutoRefreshEnabled: true
			});

			this.initialized = true;
		} catch (error) {
			console.error("Failed to initialize reCAPTCHA:", error);
			throw error;
		}
	}

	/**
	 * Checks if reCAPTCHA is initialized.
	 */
	public isInitialized(): boolean {
		return this.initialized;
	}
}

export const reCaptchaService = ReCaptchaService.getInstance();
