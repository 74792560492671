import { createSelector } from "@reduxjs/toolkit";
import { ICompany, ICompanyForm } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Company.
 */
export const CompanySelectors = {
	getCompanies: (state: RootState): ICompany[] => state.companies.list,
	getCompanyByIds: createSelector(
		(state: RootState) => state,
		(state: RootState, itemIds: string[]) => itemIds,
		(state: RootState, itemIds: string[]): ICompany[] =>
			state.companies.list.filter((company) =>
				itemIds.includes(company.id)
			)
	),
	getCompanyById: createSelector(
		(state: RootState) => state,
		(state: RootState, itemId: string) => itemId,
		(state: RootState, itemId: string): ICompany =>
			state.companies.list.find((company) => company.id === itemId)
	),
	getCompanyFormById: createSelector(
		(state: RootState) => state,
		(state: RootState, itemId: string) => itemId,
		(state: RootState, itemId: string): ICompanyForm => {
			const companyFound = CompanySelectors.getCompanyById(state, itemId);

			return {
				name: companyFound.name,
				foundedOn: companyFound.foundedOn,
				contactMail: companyFound.contactMail,
				contactPhone: companyFound.contactPhone,
				contactWhatsApp: companyFound.contactWhatsApp
			};
		}
	),
	getCompaniesLoading: (state: RootState): boolean => state.companies.loading,
	getCompaniesQueried: (state: RootState): boolean => state.companies.queried,
	getIsEditing: (state: RootState): boolean => state.companies.editing,
	getCompaniesEditing: (state: RootState): ICompany[] =>
		state.companies.editingList
	// getCompaniesError: (state: RootState) => state.companies.error
};
