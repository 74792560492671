/**
 * Dashboard Graph Data model.
 */
export interface IDashboardGraphData {
	chartId: string;
	columns: any[];
	data: [];
}

/**
 * Dashboard Graph Data model.
 */
export class DashboardGraphData implements IDashboardGraphData {
	chartId: string;
	columns: any[];
	data: [];

	constructor(chartId: string, columns: any[], data: []) {
		this.chartId = chartId;
		this.columns = columns;
		this.data = data;
	}
}

/**
 * Dashboard Graph Data Redux slice.
 */
export interface IDashboardGraphDataSlice {
	activeChartConfig: {
		chartId: string;
		columns: any[];
	};
	chartColumnsById: {
		[key: string]: any[];
	};
	chartDataById: {
		[key: string]: any[];
	};
	loadedById: {
		[key: string]: boolean;
	};
	loadingById: {
		[key: string]: boolean;
	};
}

/**
 * Initial state for the dashboard graph data slice.
 */
export const dashboardGraphDataInitialState: IDashboardGraphDataSlice = {
	activeChartConfig: {
		chartId: "",
		columns: []
	},
	chartColumnsById: {},
	chartDataById: {},
	loadedById: {},
	loadingById: {}
};
