// React TabList component, using Tab component as a collection of tabs
// Also, creates a list of buttons on top of the tabs to navigate between them

import React, { useMemo, useState } from "react";

import { Tab, ITabProps } from "./Tab";

import "./shared/TabStyles.scss";

/**
 * Contract for the Properties of the Tabs component
 */
interface ITabListProps {
	tabs: ITabProps[];
	wrapTabs?: undefined | boolean;
}

/**
 * Organizer component for tab content,
 * Must be used with Tabs component
 *
 * @param param0
 * @returns
 */
function TabList({ tabs, wrapTabs = false, ...rest }: ITabListProps) {
	const [activeTab, setActiveTab] = useState(tabs[0].id ?? "");

	const customTabs = useMemo(
		() => tabs.filter((tab) => !tab.children),
		[tabs]
	);

	const commonTabs = useMemo(
		() => tabs.filter((tab) => tab.children),
		[tabs]
	);

	const displayingTab = useMemo(
		() =>
			tabs.length > 0 ? tabs.find((tab) => tab.id === activeTab) : null,
		[activeTab, tabs]
	);

	const handleTabButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>,
		id: string
	) => {
		event.preventDefault();

		setActiveTab(id);
	};

	return (
		<div className="tab-container" {...rest}>
			<div className={`tab-buttons ${wrapTabs ? "flex-wrap" : ""}`}>
				{commonTabs.map((tab) => (
					<button
						key={`button-${tab.id}`}
						onClick={(event) => handleTabButtonClick(event, tab.id)}
						className={`tab-button ${
							activeTab === tab.id ? "active" : ""
						}`}
					>
						{tab.title}
					</button>
				))}
				{customTabs.map((tab, idx) =>
					typeof tab.render === "function" ? (
						tab.render(tab, idx)
					) : (
						<button
							disabled
							key={`button-${tab.id}`}
							className={`tab-button`}
						>
							{tab.title}
						</button>
					)
				)}
			</div>
			<div className="tab-list">
				{displayingTab ? (
					<Tab
						title={displayingTab.title}
						key={displayingTab.id}
						id={displayingTab.id}
					>
						{displayingTab.children}
					</Tab>
				) : null}
			</div>
		</div>
	);
}

export { ITabListProps, TabList };
