import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";
import { IOption } from "components/shared/dropdown";
import { ICompany } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Company Admin.
 */
export const CompanyAdminSelectors = {
	getCompanyAdminList: (state: RootState): ICompany[] => state.companies.list,
	getCompanyAdminDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.companyAdmin.list?.map((companyItem: ICompany) => ({
				text: companyItem.name,
				key: companyItem.id
			})),
		(list: IDropdownOption<IOption>[]) => list
	),
	getCompanyAdminById: (state: RootState, id: string): null | ICompany =>
		state.companyAdmin.list.find(
			(stockItem: ICompany) => stockItem.id === id
		),
	getCompanyAdminListLoading: (state: RootState): boolean =>
		state.companyAdmin.loading,
	getCompanyAdminListQueried: (state: RootState): boolean =>
		state.companyAdmin.queried,
	getCompanyAdminListQuerying: (state: RootState): boolean =>
		state.companyAdmin.loading,
	getIsEditing: (state: RootState): boolean => state.companies.editing,
	getCompanyAdminListEditing: (state: RootState): ICompany[] =>
		state.companyAdmin.editingList,
	getCompanyAdminModalOpen: (state: RootState): boolean =>
		state.companyAdmin.modalOpen,
	getCompanyAdminFormData: (state: RootState): ICompany =>
		state.companyAdmin.formData
	// getCompanyAdminListError: (state: RootState) => state.companies.error
};
