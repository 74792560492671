import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { UserSelectors } from "redux/selectors";
import { InputText } from "components/shared/input";

import "./UserProfileForm.scss";

export declare type SetterFunction = (
	propName: string,
	newValue: string | number | boolean
) => void;

export const FieldGroup = (props) => {
	return <div className="field-group">{props.children}</div>;
};

export interface IFieldGroupValueProps {
	id: string;
	name: string;
	setter: SetterFunction;
	isEditing: boolean;
	disabled?: undefined | boolean;
}

export const FieldGroupValue: React.FC<IFieldGroupValueProps> = (props) => {
	const userProfileData = useSelector(UserSelectors.selectUserProfile);
	const handleChange =
		(setter: SetterFunction) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setter(event.target.name, event.target.value);
		};
	const value = useMemo(
		() => userProfileData[props.name],
		[userProfileData, props.name]
	);

	return (
		<div>
			{props.isEditing ? (
				<InputText
					id={props.id}
					name={props.name}
					value={value}
					onChange={handleChange(props.setter)}
					disabled={props.disabled}
				/>
			) : undefined !== value && value !== "" ? (
				value
			) : (
				"-"
			)}
		</div>
	);
};
