import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { UserSelectors } from "redux/selectors";

/**
 * Wrapper for tracking the Active Company,
 * and when changes occur.
 *
 * @param svc - Service to be used
 * @returns Service instance or null
 */
export const useActiveCompanyWrapper = <T>(
	svc: () => T,
	canCallWithoutCompany: undefined | boolean = false
): T | null => {
	const lastActiveCompany = useRef(null);
	const activeCompany = useSelector(
		UserSelectors.selectUserProfileActiveCompany
	);

	const serviceInstance = useMemo<T | null>(() => {
		if (activeCompany !== lastActiveCompany.current) {
			lastActiveCompany.current = activeCompany;
		}

		return ((activeCompany && activeCompany !== "") ||
			canCallWithoutCompany) &&
			svc &&
			typeof svc === "function"
			? svc()
			: null;
	}, [svc, activeCompany, canCallWithoutCompany]);

	return serviceInstance;
};
