import {
	Unsubscribe,
	User,
	browserLocalPersistence,
	getAuth,
	onAuthStateChanged,
	setPersistence,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut
} from "firebase/auth";

// import { StateService } from "./StateService";
import { appStateService, store } from "App";
import { actions } from "redux/reducers/user/userProfileReducer";
import { UserSelectors } from "redux/selectors";

import { IUserProfile, IUserProfileSlice } from "models";
import { translate } from "hooks/i18n";
import { StoreType } from "types";
import { Timestamp } from "firebase/firestore";
// import { configAppModules } from "components/modules";

/**
 * Decides if the user is authenticated or not, and loads the state accordingly.
 *
 * @param user The user data
 * @param authState The authentication state
 */
function _decideAndLoadAuthState(
	user: User,
	authState: IUserProfileSlice,
	onAuthenticated: (_user: User, _state: IUserProfileSlice) => void,
	onAnonymous: () => void,
	skipResetOnAnonymous: boolean = false
): boolean {
	let result: boolean = false;

	if (!!user) {
		// User is signed in, see docs for a list of available properties
		// https://firebase.google.com/docs/reference/js/firebase.User
		if (!authState?.authenticated || authState?.profile?.id !== user.uid) {
			if (typeof onAuthenticated === "function")
				onAuthenticated(user, authState);
		}

		result = true;
	} else if (authState?.authenticated) {
		if (!skipResetOnAnonymous && typeof onAnonymous === "function")
			onAnonymous();
	}

	return result;
}

/**
 * Class for managing the authentication process as a service.
 */
class AuthService {
	_store: StoreType = null;

	constructor(reduxStore: StoreType) {
		this._store = reduxStore;
	}

	/**
	 * Authenticates the user with the given credentials.
	 * At the moment, implements a call to firebase/auth.
	 *
	 * @param user The user name
	 * @param pwd The user password
	 *
	 * @returns A promise with the authentication result as boolean.
	 */
	async authenticate(user: string, pwd: string): Promise<boolean> {
		let result = false;
		// firebase authentication using appStateService.firebase.auth
		// appStateService.firebase.app
		// 	.auth()

		if (!user || !pwd) {
			appStateService.appManager.showWarning(
				"page.login.form.submit.error"
			);
			return result;
		}

		this._store.dispatch(actions.setAuthenticating(true));
		appStateService.appManager.setLoading(true);

		const auth = getAuth();
		await setPersistence(auth, browserLocalPersistence).then(() => {
			return signInWithEmailAndPassword(auth, user, pwd)
				.then(async (userCredential) => {
					// Signed in, prepares the available data for the state layer
					const user = userCredential.user;
					let userProfileData: IUserProfile = {
						id: user.uid,
						name:
							user.displayName ?? user.email?.split("@")[0] ?? "",
						displayName: user.displayName ?? "",
						mail: user.email,
						pictureURL: user.photoURL ?? "",
						timestamp: Timestamp.now(),
						deleted: false,
						activeCompany: "",
						companies: [],
						roles: {
							admin: false
						},
						customerId: "",
						firstAccess: true
					};

					// TODO: Implement the company selection service and invoke

					// // Re-assigning the firestore instance to the appStateService
					// appStateService.restartService("firestore", <
					// 	Firestore
					// >() => {
					// 	return getFirestore() as Firestore;
					// });
					// appStateService.firestore = getFirestore();

					store.dispatch(actions.setUser(userProfileData));
					store.dispatch(actions.setAuthenticated(true));

					// Tries to locate the user in the database
					// If not located, updates the database to include the object
					await appStateService.user.checkUserExistsByID(
						userProfileData.id,
						async (data) => {
							// User was found, updates the local auth state
							store.dispatch(
								actions.setUser({ ...userProfileData, ...data })
							);
							// Also, initiates the request for user companies' data
							await appStateService.company.service
								.get()
								.getUserCompanies();
						}
					);

					// TODO: Store user in localstorage

					result = true;
				})
				.catch((error) => {
					this.handleAuthError(error, (message) => {
						store.dispatch(actions.setAuthenticating(false));
						// appStateService.appManager.showMessage(message);
					});

					// TODO: log error, using external service
				});
		});

		appStateService.appManager.setLoading(false);

		return result;
	}

	/**
	 * Logs off the current user.
	 * At the moment, implements a call to firebase/auth.
	 */
	async logOff(): Promise<void> {
		const auth = getAuth();
		await signOut(auth)
			.then(() => {
				// Sign-out successful.
				AuthService.resetStatic();
			})
			.catch((error) => {
				// An error happened.
				appStateService.appManager.showError(String(error));
			});
	}

	reset(): void {
		this._store.dispatch(actions.reset());
	}

	/**
	 * Resets the local user state.
	 */
	static resetStatic(): void {
		store.dispatch(actions.reset());
	}

	/**
	 * Monitors if there is a local authenticated user action.
	 * At the moment, implements a call to firebase/auth.
	 *
	 * @returns A promise with the verification result as boolean.
	 */
	monitorAuthState(): Unsubscribe {
		// let result: boolean;
		let unsubscribe: Unsubscribe;

		// TODO: Verify in localstorage if there is a user logged in
		const auth = getAuth();
		const persistedState = UserSelectors.select(store.getState());

		// Prepares the authenticating state
		store.dispatch(actions.setAuthenticating(true));

		// Creates an unsubscriber which will be returned to the caller
		unsubscribe = onAuthStateChanged(
			auth,
			(user) => {
				// Decides the auth state and treats it
				_decideAndLoadAuthState(
					user,
					persistedState,
					(_user) => {
						AuthService.setLocalUserStatic(_user, persistedState);
						// configAppModules();
					},
					() => {
						AuthService.resetStatic();
					},
					false
				);

				// Clean-up authenticating state
				store.dispatch(actions.setAuthenticating(false));
			},
			(error) => {
				// Clean-up authenticating state
				appStateService.appManager.showError(String(error));
				store.dispatch(actions.setAuthenticating(false));
			}
		);

		return unsubscribe;
	}

	/**
	 * Verifies if there is a local authenticated user.
	 * At the moment, implements a call to firebase/auth.
	 *
	 * @returns A promise with the verification result as boolean.
	 */
	async verifyLocalAuth(): Promise<boolean> {
		let result: boolean = false;

		// TODO: Verify in localstorage if there is a user logged in
		const auth = getAuth();
		const user = auth.currentUser;
		const persistedState = this.getFromLocalState();

		result = this.decideAndLoadAuthState(user, persistedState, true);

		// configAppModules();

		return result;
	}

	/**
	 * Decides if the user is authenticated or not, and loads the state accordingly.
	 *
	 * @param user The user data
	 * @param authState The authentication state
	 */
	decideAndLoadAuthState(
		user: User,
		authState: IUserProfileSlice,
		skipResetOnAnonymous: boolean = false
	): boolean {
		return _decideAndLoadAuthState(
			user,
			authState,
			(user) => AuthService.setLocalUserStatic(user, authState),
			() => {
				AuthService.resetStatic();
			},
			skipResetOnAnonymous
		);

		// let result: boolean = false;

		// if (!!user) {
		// 	// User is signed in, see docs for a list of available properties
		// 	// https://firebase.google.com/docs/reference/js/firebase.User
		// 	if (!authState?.authenticated || authState?.id !== user.uid) {
		// 		this.setLocalUser(user);
		// 	}

		// 	result = true;
		// } else if (authState?.authenticated) {
		// 	if (!skipResetOnAnonymous) this.reset();
		// }

		// return result;
	}

	/**
	 * Requests a password reset email.
	 *
	 * @param email The user email
	 * @returns
	 */
	async requestPasswordReset(email: string): Promise<boolean> {
		let result: boolean = false;
		const auth = getAuth();
		await sendPasswordResetEmail(auth, email)
			.then(() => {
				result = true;
				// Password reset email sent!
				appStateService.appManager.showSuccess(
					"page.login.form.passwordResetEmailSent"
				);
			})
			.catch((error) => {
				// Shows error message to end-user
				this.handleAuthError(error);
			});

		return result;
	}

	/**
	 * Gets the local user state.
	 *
	 * @returns The user data
	 */
	getFromLocalState(): IUserProfileSlice {
		const persistedState = UserSelectors.select(store.getState());

		// if (!persistedState?.authenticated) return null;

		return persistedState;
	}

	// /**
	//  * Sets the local user state.
	//  *
	//  * @param user The user data
	//  */
	// private setLocalUser(user: User): void {
	// 	return AuthService.setLocalUserStatic(user);
	// }

	/**
	 * Sets the local user state.
	 *
	 * @param user The user data
	 */
	private static setLocalUserStatic(
		user: User,
		rehydratingState?: undefined | IUserProfileSlice
	): void {
		const userProfileData: IUserProfile = !user
			? null
			: {
					id: rehydratingState?.profile?.id ?? user?.uid ?? "",
					name:
						rehydratingState?.profile?.name ??
						user?.displayName ??
						user?.email?.split("@")?.[0] ??
						"",
					displayName:
						rehydratingState?.profile?.displayName ??
						user?.displayName ??
						"",
					mail: rehydratingState?.profile?.mail ?? user?.email ?? "",
					pictureURL:
						rehydratingState?.profile?.pictureURL ??
						user?.photoURL ??
						"",
					timestamp:
						rehydratingState?.profile?.timestamp ?? Timestamp.now(),
					deleted: rehydratingState?.profile?.deleted ?? false,
					activeCompany:
						rehydratingState?.profile?.activeCompany ?? "",
					roles: rehydratingState?.profile?.roles ?? {
						admin: false
					},
					companies: rehydratingState?.profile?.companies ?? [],
					customerId: rehydratingState?.profile?.customerId ?? "",
					firstAccess: rehydratingState?.profile?.firstAccess ?? true
			  };

		// sets the authenticated user
		store.dispatch(actions.setUser(userProfileData));
		store.dispatch(actions.setAuthenticated(!!userProfileData));
	}

	/**
	 *
	 * @param user
	 * @param onLoading
	 * @param onLoaded
	 */
	login(
		user,
		onLoading: () => Promise<void>,
		onLoaded: () => Promise<void>
	): void {
		// Only authenticated once, since there are control flags now
		// If the process is loading (started), won't enter the if.
		// Or if the process is loaded (concluded), won't enter the if.
		// Or if the authentication was initialized, won't enter the if.

		if (UserSelectors.selectAuthenticating(this._store.getState())) {
		}

		if (typeof onLoading === "function") Promise.resolve(onLoading());

		// not async
		appStateService.user.setId(user.id);

		// not async
		appStateService.user.setName(user.name);

		// not async
		appStateService.user.setEmail(user.email);

		// not async
		appStateService.user.setIsAdmin(user.isAdmin);

		if (typeof onLoaded === "function") Promise.resolve(onLoaded());
	}

	setAuthenticating(authenticating: boolean): void {
		this._store.dispatch(actions.setAuthenticating(authenticating));
	}

	setAuthenticated(authenticated: boolean): void {
		this._store.dispatch(actions.setAuthenticated(authenticated));
	}

	handleAuthError(
		error: any,
		onMessageReady: (message: string) => void = undefined
	): void {
		const errorCode = `${error.code}`;
		let errorMessage = error.message;

		// Switches between Firebase Auth error codes
		// and shows the corresponding message
		switch (errorCode) {
			case "auth/wrong-password":
				errorMessage = translate("page.login.form.submit.error");
				break;
			case "auth/user-not-found":
				errorMessage = translate(
					"page.login.form.submit.error.mailNotFound"
				);
				break;
			case "auth/network-request-failed":
				errorMessage = translate(
					"page.login.form.submit.error.offline"
				);
				break;
			case "auth/missing-email":
				errorMessage = translate("page.login.form.empty.mail");
				break;
			case "auth/invalid-email":
				errorMessage = translate(
					"page.login.form.submit.error.invalidMail"
				);
				break;

			case "400":
				errorMessage = translate("page.login.form.submit.error");
				break;
			default:
				break;
		}

		// Invokes the onMessageReady (optionally), then shows an error message to end-user
		if (typeof onMessageReady === "function") onMessageReady(errorMessage);
		appStateService.appManager.showWarning(errorMessage);
	}
}

export { AuthService };
