import { appStateService, store } from "App";
import {
	ClientOrderStatus,
	OrderItemStatus,
	StockMovementDirection,
	SumUpPaymentPurpose
} from "enums";
import { Unsubscribe } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { translate } from "hooks/i18n";
import { ClientOrder, IClientOrder, IOrderItem } from "models/index";
import { actions } from "redux/reducers/orders/client/reducer";
import { ClientOrderSelectors } from "redux/selectors";
import { CompanySegment } from "services/companies/CompanySegment";

/**
 * Class for managing the basics of the Company's Client Order data list.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyOrderService extends CompanySegment<IClientOrder> {
	constructor(companyId: string) {
		super(companyId, "clientOrders", actions.setList, actions.setQueried);
	}

	/**
	 * Overrides the base createItem, since a specific approach
	 * needs to take place for Checkout generation.
	 *
	 * @param item The item to be created.
	 * @param onCreated [Optional] Callback function to be executed after the item is created.
	 */
	async createItem(
		item: IClientOrder,
		onCreated?: (item: IClientOrder) => void
	): Promise<boolean> {
		if (!item) return false;

		if (item.items.length > 0) {
			item.items.forEach((orderItem) => {
				orderItem.status = OrderItemStatus.pending;
				orderItem.timestampByStatus = {
					...orderItem.timestampByStatus,
					[OrderItemStatus.pending]: Timestamp.now()
				};
			});
		}

		let response = await super
			.createItem(item, async (createdItem: IClientOrder) => {
				if (typeof onCreated === "function") onCreated(createdItem);

				appStateService.appManager.showSuccessToast(
					translate("page.order.toast.success.createOrder", {
						orderId: createdItem.id
					})
				);

				// Sets the order as requires payment by default
				await this.updateItem(
					{
						...createdItem,
						status: ClientOrderStatus.needsPayment
					},
					async (updatedItem) => {
						// FIXME: Simulate a checkout finalization process, for now
						if (response) {
							// TODO: Create a checkout
							// response = await this.createCheckout(item.id);

							// Creates an inventory movement entry
							await this.createInventoryMovement(
								updatedItem,
								StockMovementDirection.out
							);

							await this.updateItem({
								...updatedItem,
								status: ClientOrderStatus.pending
							});
						}
					}
				);
			})
			.catch((error) => {
				appStateService.appManager.showError(
					translate("page.order.toast.error.saveOrder", {
						orderId: item.id
					})
				);
				return false;
			});

		return response;
	}

	/**
	 * Creates a Purchase checkout, since a specific approach
	 * needs to take place for payment generation.
	 *
	 * @param orderId The item ID to be generated a payment from.
	 * @param onUpdated [Optional] Callback function to be executed after the item is updated.
	 */
	async createCheckout(orderId: string): Promise<boolean> {
		let newCheckout;
		let currentOrder: IClientOrder;
		const orderDate = new Date();
		const orderValidUntil = orderDate;

		// Adds 2 days to the order expiration date
		orderValidUntil.setDate(orderDate.getDate() + 2);

		currentOrder = await this.getItemById(orderId);

		newCheckout = {
			amount: currentOrder.payment.value,
			currency: "BRL",
			checkout_reference: orderId,
			date: orderDate.toISOString(),
			description: `${appStateService.payment.sumup.getMerchantName()}: Pedido de Compra ID ${orderId}`,
			merchant_code: appStateService.payment.sumup.getMerchantCode(),
			pay_to_email: appStateService.payment.sumup.getMerchantMail(),
			payment_type: currentOrder.payment.paymentType,
			purpose: SumUpPaymentPurpose.SETUP_RECURRING_PAYMENT,
			redirect_url: "",
			valid_until: orderValidUntil.toISOString()
		};

		const checkoutResponse = await appStateService.payment.sumup
			.createCheckout(newCheckout)
			.catch((error) => {
				appStateService.appManager.showError(
					translate("page.order.toast.error.checkoutCreate", {
						orderId
					})
				);
				return error;
			});

		if (!checkoutResponse) {
			await this.error(
				orderId,
				translate("page.order.internal.checkout.criticalError"),
				ClientOrderStatus.cancelled
			);
			return false;
		}

		if (
			checkoutResponse instanceof Error &&
			checkoutResponse.message &&
			checkoutResponse.stack
		) {
			await this.error(orderId, checkoutResponse.message);
			appStateService.appManager.showError(checkoutResponse.message);
			return false;
		}

		this.updateItem({
			...currentOrder,
			payment: {
				...currentOrder.payment,
				checkoutId: checkoutResponse.id
			}
		});

		appStateService.appManager.showSuccessToast(
			translate("page.order.toast.success.checkoutCreate", {
				orderId
			})
		);

		return true;
	}

	/**
	 * Updates the status of the order to "error",
	 * As well as maintains the error message.
	 *
	 * @param orderId
	 * @param errorMessage
	 */
	async error(
		orderId: string,
		errorMessage: string,
		status: ClientOrderStatus = ClientOrderStatus.error
	) {
		const currentOrder = await this.getItemById(orderId);

		if (!currentOrder) return;

		// Gives back the stock, when erroring out the Order
		await this.createInventoryMovement(
			currentOrder,
			StockMovementDirection.in
		);

		this.updateItem({
			...new ClientOrder(currentOrder),
			status: ClientOrderStatus.error,
			errorMessage
		});

		appStateService.appManager.showError(errorMessage);
	}

	/**
	 * Gets all items which are pending, in progress, packaging or ready to deliver.
	 * That means these are all active orders in production.
	 *
	 * @param onReady Callback function to be executed after the items are ready.
	 *
	 * @returns The list of active orders in production.
	 */
	async getAllProduction(
		onReady: (items: IClientOrder[]) => void
	): Promise<Unsubscribe> {
		const productionStates = [
			ClientOrderStatus.pending,
			ClientOrderStatus.inProgress,
			ClientOrderStatus.packaging,
			ClientOrderStatus.readyToDeliver,
			ClientOrderStatus.tableServed
		];
		return await this.getAllLive((items) => {
			const preparedOrders = items
				.filter((order) => {
					return productionStates.includes(order.status);
				})
				.sort(
					(itemA, itemB) =>
						itemA.timestamp.toMillis() - itemB.timestamp.toMillis()
				);

			if (typeof onReady === "function") onReady(preparedOrders);
		});
	}

	/**
	 * Using the instance of the order, creates an inventory movement entry.
	 * Should instantiate the StockMovementService from the appStateServices.
	 * Entry should be remove or add, directions of movement of a stock entry.
	 *
	 * @param order The order to be used to create the inventory movement.
	 */
	async createInventoryMovement(
		order: IClientOrder,
		direction: StockMovementDirection
	): Promise<boolean> {
		const stockMovementService =
			appStateService.company.stockMovement.get();
		const stockService = appStateService.company.stock.get();

		if (!stockMovementService || !stockService || !order) {
			appStateService.appManager.showError(
				translate("page.order.toast.error.inventoryMovement", {
					orderId: order?.id
				})
			);
			return false;
		}

		const stockEntries = order.items.map(async (productInCart) => {
			const productStockEntries = await stockService.getByProductId(
				productInCart.itemId
			);

			return productStockEntries.find(
				(stockEntry) => !stockEntry.deleted
			);
		});

		Promise.all(stockEntries)
			.then((entries) => {
				entries.forEach((stockEntry) => {
					const itemQuantity =
						order.items.find(
							(orderItem) =>
								orderItem.itemId === stockEntry.productId
						)?.quantity ?? 0;

					stockMovementService.createItem(
						{
							id: null,
							productId: stockEntry.productId,
							productSku: stockEntry.productSku,
							productBarCode: stockEntry.productBarCode,
							quantity: itemQuantity,
							stockId: stockEntry.id,
							direction: direction,
							timestamp: Timestamp.now()
						},
						(addedItem) => {
							stockService.updateItem(
								{
									...stockEntry,
									quantity:
										addedItem.direction ===
										StockMovementDirection.in
											? stockEntry.quantity +
											  addedItem.quantity
											: StockMovementDirection.out
											? stockEntry.quantity -
											  addedItem.quantity
											: StockMovementDirection.destroy
											? 0
											: stockEntry.quantity
								},
								() => {
									appStateService.appManager.showSuccessToast(
										translate(
											"page.order.toast.success.inventoryMovement",
											{
												orderId: order.id
											}
										)
									);
								}
							);
						}
					);
				});
			})
			.catch(() => {
				appStateService.appManager.showError(
					translate("page.order.toast.error.inventoryMovement", {
						orderId: order.id
					})
				);
			});
	}

	/**
	 * Updates an item from the order, which could be preparing (in progress), packaging or ready to deliver.
	 *
	 * @param orderId The order ID to be updated.
	 * @param nextItem The item to be updated.
	 * @param nextState The next state of the item.
	 * @param onUpdated [Optional] Callback function to be executed after the item is updated.
	 */
	async updateOrderItem(
		orderId: string,
		nextItem: IOrderItem,
		nextState: OrderItemStatus,
		onUpdated: (updatedItem: IClientOrder) => Promise<void>
	): Promise<void> {
		const order = await this.getItemById(orderId);

		if (!order) return;

		const updatedOrder: IClientOrder = {
			...order,
			items: order.items.map((orderItem) => {
				if (orderItem.itemId === nextItem.itemId) {
					return {
						...orderItem,
						timestampByStatus: {
							...(orderItem?.timestampByStatus ?? {
								[OrderItemStatus.new]: Timestamp.now(),
								[OrderItemStatus.pending]: "",
								[OrderItemStatus.preparing]: "",
								[OrderItemStatus.tableServed]: "",
								[OrderItemStatus.ready]: "",
								[OrderItemStatus.delivered]: "",
								[OrderItemStatus.cancelled]: ""
							}),
							...(nextItem?.timestampByStatus ?? {})
						},
						status: nextState
					};
				}
				return orderItem;
			})
		};

		await this.updateItem(updatedOrder, onUpdated)
			.then(() => {
				appStateService.appManager.showSuccessToast(
					translate("page.order.toast.success.itemStatusChange", {
						itemName: nextItem.itemId,
						status: nextState
					})
				);
			})
			.catch(() => {
				appStateService.appManager.showError(
					translate("page.order.toast.error.itemStatusChange", {
						itemName: nextItem.itemId,
						status: nextState
					})
				);
			});
	}

	/**
	 * Gets the state of Loading slice property.
	 *
	 * @returns The state of the Loading slice property.
	 */
	getLoading(): boolean {
		return ClientOrderSelectors.getClientOrdersLoading(store.getState());
	}

	/**
	 * Gets the state of the List slice property.
	 *
	 * @returns The state of the List slice property.
	 */
	getList(): IClientOrder[] {
		return ClientOrderSelectors.getClientOrders(store.getState());
	}

	/**
	 * Gets the state of the Queried slice property.
	 *
	 * @returns The state of the Queried slice property.
	 */
	getQueried(): boolean {
		return ClientOrderSelectors.getClientOrdersQueried(store.getState());
	}
}

export { CompanyOrderService };
