import React, { useEffect, useMemo } from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { MessageModalType } from "enums";

import "./Toast.scss";

/**
 * Toast component props.
 */
export interface IToastProps {
	message: string;
	type?: MessageModalType;
	onDismiss?: () => void;
	autoClose?: boolean;
	autoCloseTime?: number;
	position?: "top" | "bottom";
	open?: boolean;
}

/**
 * Toast component.
 * @param props - The props for the toast component.
 * @returns The toast component.
 */
export const Toast: React.FC<IToastProps> = ({
	message,
	type = MessageModalType.info,
	onDismiss,
	open = true,
	autoClose = true,
	autoCloseTime = 7500,
	position = "top"
}) => {
	useEffect(() => {
		let timer: NodeJS.Timeout;

		if (autoClose && onDismiss && open && !timer) {
			timer = setTimeout(onDismiss, autoCloseTime);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [autoClose, autoCloseTime, onDismiss, open]);

	const resolvedType = useMemo(() => {
		switch (type) {
			case MessageModalType.error:
				return MessageBarType.error;
			case MessageModalType.warning:
				return MessageBarType.warning;
			case MessageModalType.success:
				return MessageBarType.success;
			case MessageModalType.info:
			default:
				return MessageBarType.info;
		}
	}, [type]);

	return open ? (
		<div className={`toast-message ${position}`}>
			<MessageBar
				messageBarType={resolvedType}
				onDismiss={onDismiss}
				dismissButtonAriaLabel="Close"
				className="fluent-toast"
				truncated={false}
			>
				{message}
			</MessageBar>
		</div>
	) : null;
};
