export const dictionary = {
	"app.authentication.request.message": "Please, authenticate.",
	"app.name": "BeeBase App",
	"app.title": "BeeBase",
	"app.description": "This is the BeeBase App",
	"app.welcome": "Welcome to BeeBase!",
	"app.bar.title": "BeeBase Admin",
	"app.bar.button.logoff": "Sign Out",
	"app.bar.button.logoff.confirm": "Are you sure you want to sign out?",
	"global.dropdown.defaultItem.placeholder": "<None>",
	"global.date.months.short.0": "Jan",
	"global.date.months.short.1": "Feb",
	"global.date.months.short.2": "Mar",
	"global.date.months.short.3": "Apr",
	"global.date.months.short.4": "May",
	"global.date.months.short.5": "Jun",
	"global.date.months.short.6": "Jul",
	"global.date.months.short.7": "Aug",
	"global.date.months.short.8": "Sep",
	"global.date.months.short.9": "Oct",
	"global.date.months.short.10": "Nov",
	"global.date.months.short.11": "Dec",
	"global.loading": "Loading...",
	"global.logout": "Logout",
	"global.login": "Login",
	"global.list.action.add": "Add",
	"global.list.action.duplicate": "Copy",
	"global.list.action.edit": "Edit",
	"global.list.action.save": "Save",
	"global.list.action.cancel": "Cancel",
	"global.list.action.delete": "Delete",
	"global.list.empty": "No items to show.",
	"global.list.count.empty": "No item",
	"global.list.count.single": "1 item",
	"global.list.count.plural": "${count} items",
	"global.list.filter.placeholder": "To filter, type here..",
	"global.userProfile.button.profile": "Preferences",
	"global.userProfile.button.signOut": "Sign Out",
	"list.categories.name": "Categories",
	"list.products.name": "Products",
	"list.brands.name": "Brands",
	"page.home.title": "BeeBase Admin",
	"page.home.welcome": "Welcome, ${username}.",
	"page.home.dashboard.title": "Company's Infographics",
	"page.login.title": "Login",
	"page.login.photoCredit": "Photo by ${author}",
	"page.login.form.title": "Login",
	"page.login.form.email": "Email",
	"page.login.form.empty.mail": "Please, inform the email.",
	"page.login.form.password": "Password",
	"page.login.form.passwordResetEmailSent": "Password reset email sent.",
	"page.login.form.resetPassword": "I forgot my password",
	"page.login.form.submit": "Sign In",
	"page.login.form.submit.passwordResetEmail": "Send Password Reset mail",
	"page.login.form.submit.error.invalidMail":
		"Invalid email format. Please, verify it.",
	"page.login.form.submit.error.mailNotFound":
		"Email not found. Please, verify it.",
	"page.login.form.submit.error":
		"Invalid user and/or password. Please, verify it and try again.",
	"page.login.form.submit.error.offline":
		"You are offline. Please, reconnect, then try again.",
	"page.categoriesList.title": "Categories",
	"page.categoriesList.onCompleteNewItemText":
		"The category ${categoryName} has been created",
	"page.categoriesList.onCompleteUpdateItemText":
		"The category ${categoryName} has been updated",
	"page.categoriesList.onDeleteItemText":
		"The category ${categoryName} has been deleted",
	"page.categoriesList.onSaveErrorText":
		"Failed to save category ${categoryName}. Please try again.",
	"page.categoriesList.onLoadErrorText":
		"Failed to load categories. Please refresh the page.",
	"page.categoriesList.onCancelText": "Category changes were cancelled.",
	"page.categoriesList.onDuplicateNameText":
		"A category with name ${categoryName} already exists.",
	"page.clientOrderList.title": "Client Orders",
	"page.clientsList.title": "Clients",
	"page.productsList.title": "Products",
	"page.productsList.action.duplicate.label": "Duplicate of",
	"page.productsList.action.duplicate.confirm.title": "Duplicate Product",
	"page.productsList.action.duplicate.confirm.message":
		"Do you confirm you want to duplicate this product?",
	"page.suppliersList.title": "Suppliers",
	"page.brandsList.title": "Brands",
	"page.landingMails.title": "Mails (Landing Page)",
	"page.stockList.title": "Products Stock",
	"page.home.chat-shortcut": "Initiate Bee AI Chat",
	"page.orderList.items.count": "${count} items.",
	"page.order.internal.pageTitle.new": "New Order",
	"page.order.internal.pageTitle.edit": "View Order",
	"page.order.management.list.empty": "No orders here",
	"page.order.management.order.item.action.idClick":
		"Click to navigate to order details",
	"abbrev.unit": "un",
	"general.notAvailable": "N/A",
	"order.list.status.new":
		"This order was just created. Awaiting customer action.",
	"order.list.status.needsPayment":
		"This order is awaiting payment by the customer.",
	"order.list.status.confirmed":
		"Payment for this order has been confirmed. Processing payment and stock movements.",
	"order.list.status.pending":
		"This order has been paid, confirmed, therefore it is awaiting internal movement.",
	"order.list.status.inProgress":
		"This order is in progress, but has not yet been packaged for shipping.",
	"order.list.status.packaging": "This order is being packaged for shipping.",
	"order.list.status.readyToDeliver":
		"This order is ready for delivery, therefore, it awaits a delivery person.",
	"order.list.status.inDelivery":
		"This order is being delivered by ${deliveryPersonName}.",
	"order.list.status.delivered":
		"This order has been successfully delivered. Awaiting customer confirmation.",
	"order.list.status.completed":
		"This order has been successfully completed.",
	"global.modal.message.action.cancel": "Cancel",
	"global.modal.message.action.confirm": "Ok",
	"global.modal.message.default.title": "Warning",
	"global.button.backToHome": "Back to Home Page",
	"global.button.confirm": "Confirm",
	"global.button.cancel": "Cancel",
	"global.button.save": "Save",
	"global.button.close": "Close",
	"global.confirmCloseWithUnsavedChanges.title": "Confirm",
	"global.confirmCloseWithUnsavedChanges.description":
		"You have unsaved changes. Are you sure you want to cancel?",
	"page.order.internal.checkout.criticalError":
		"Cancelled: Critical error during checkout process. Please, contact support.",
	"page.order.internal.checkout.success": "Order created successfully",
	"page.order.internal.modalTitle.new": "New Order",
	"page.order.internal.modalTitle.edit": "Order ${orderId}",
	"global.you": "You",
	"page.signup.pageTitle": "Let's get started",
	"page.signup.description": "It's great to have you here at BeeBase!",
	"page.signup.formRedirecting": "Redirecting to login page...",
	"dashboard.groups.title": "Company's Infographic",
	"page.stockMovementList.title": "Inventory Movements",
	"page.clientTables.title": "Tables",
	"order.status.new": "New",
	"order.status.needsPayment": "Needs Payment",
	"order.status.confirmed": "Confirmed",
	"order.status.pending": "Pending",
	"order.status.inProgress": "In Progress",
	"order.status.packaging": "Packaging",
	"order.status.tableServed": "Table Served",
	"order.status.readyToDeliver": "Ready to Deliver",
	"order.status.inDelivery": "In Delivery",
	"order.status.delivered": "Delivered",
	"order.status.completed": "Completed",
	"order.status.cancelled": "Cancelled",
	"order.status.rejected": "Rejected",
	"order.status.error": "Error",
	"chart.trend.label": "Trend",
	"chart.newClients.label": "New Clients Count",
	"chart.trend.initialColor": "rgba(255, 206, 86, 1)",
	"chart.trend.successColor": "rgba(75, 192, 192, 1)",
	"chart.trend.negativeColor": "rgba(255, 99, 132, 1)",
	"chart.trend.noChangeColor": "rgba(201, 203, 207, 1)",
	"userProfileForm.formTitle": "My Profile",
	"userProfileForm.sectionTitle": "Personal Information",
	"userProfileForm.propTitles.userName": "Name",
	"userProfileForm.propTitles.userDisplayName": "Preferred Name",
	"userProfileForm.propTitles.userMail": "Email",
	"userProfileForm.propTitles.isAdmin": "Administrator",
	"userProfileForm.ariaLabels.editUserName": "Edit User Name",
	"userProfileForm.ariaLabels.editDisplayName": "Edit Preferred Name",
	"product.enabled": "Enabled",
	"product.enabled.true": "Yes",
	"product.enabled.false": "No",
	"product.enabled.hint":
		"If disabled, the product will not be available for sale.",
	"product.production": "Produced",
	"product.production.true": "Yes",
	"product.production.false": "No",
	"product.production.hint":
		"If item must be produced, it will have commands in the production panel.",
	"product.variations.title": "Variations",
	"product.variations.add": "Add Variation",
	"product.variations.empty": "No variations added.",
	"product.variations.hint":
		"Variations are different versions of the same product.",
	"product.variations.placeholder": "Variation Name",
	"product.additionalItems.title": "Additional Items",
	"product.additionalItems.add": "Add Additional Item",
	"product.additionalItems.empty": "No additional items added.",
	"product.additionalItems.hint":
		"Additional items are optional items that can be added to the product.",
	"product.additionalItems.placeholder": "Additional Item Name",
	"page.companyLinksList.title": "Company Links",
	"page.companyLinksList.onCompleteNewItemText":
		"The link ${linkName} has been created",
	"page.companyLinksList.onCompleteUpdateItemText":
		"The link ${linkName} has been updated",
	"page.companyLinksList.onDeleteItemText":
		"The link ${linkName} has been deleted",
	"page.companyLinksList.onSaveErrorText":
		"Failed to save link ${linkName}. Please try again.",
	"page.companyLinksList.onLoadErrorText":
		"Failed to load links. Please refresh the page.",
	"page.companyLinksList.onCancelText": "Link changes were cancelled.",
	"page.companyLinksList.onDuplicateNameText":
		"A link with name ${linkName} already exists.",
	"productDetails.title": "Details",
	"productDetails.radio.select": "Select",
	"productDetails.radio.add": "Add",
	"productDetails.label.id": "ID (System)",
	"productDetails.label.name": "Name (Product)",
	"productDetails.label.sku": "SKU (Unique Code)",
	"productDetails.label.category": "Category",
	"productDetails.label.brand": "Brand",
	"productDetails.label.description": "Description",
	"productDetails.label.barCode": "Bar Code",
	"productDetails.input.search": "Search",
	"productDetails.input.add": "Add",
	"productDetails.actions.generateDescription":
		"Generate description using AI (Bee AI)",
	"productDetails.placeholders.name":
		"Example: White T-Shirt Size M, Blue Jeans Size 40.",
	"list.clientTables.busy": "Busy",
	"list.clientTables.free": "Free",
	"productPricingSection.title": "Pricing",
	"productPricingSection.costLabel": "Acquisition Cost (R$)",
	"productPricingSection.transportPercentLabel": "% Transport",
	"productPricingSection.creditCardMarginLabel": "Credit Card Margin",
	"productPricingSection.variableCostMarginLabel": "% Variable Costs",
	"productPricingSection.wagePercentLabel": "% Profit",
	"productPricingSection.minimumPriceLabel": "Minimum Price (R$)",
	"productPricingSection.salePriceLabel": "Sale Price (R$)",
	"productPricingSection.searchAveragePriceButton":
		"Search Average Market Price (Bee AI)",
	"productPricingSection.nameRequiredWarning":
		"Product name is required to use this feature.",
	"paymentType.creditCard": "Credit Card",
	"paymentType.debitCard": "Debit Card",
	"paymentType.cash": "Cash",
	"paymentType.pix": "PIX",
	"paymentType.googlePay": "Google Pay",
	"paymentType.picPay": "PicPay",
	"paymentType.all": "All",
	"page.companiesList.title": "Companies (Admin)",
	"companyAdmin.form.title.new": "New Company",
	"companyAdmin.form.title.edit": "Company: ${companyName}",
	"companyAdmin.form.id": "ID (System)",
	"companyAdmin.form.name": "Company Name",
	"companyAdmin.form.name.placeholder": "Company Name",
	"companyAdmin.form.name.required": "Company name is required.",
	"companyAdmin.form.companyProfile": "Company Profile",
	"companyAdmin.form.companyProfile.adminProfileFull": "Admin Profile",
	"companyAdmin.form.companyProfile.appNone": "No Plan",
	"companyAdmin.form.companyProfile.appBasicPlan": "App Basic Plan",
	"companyAdmin.form.companyProfile.appMasterPlan": "App Master Plan",
	"companyAdmin.form.active": "Active",
	"companyAdmin.form.active.true": "Yes",
	"companyAdmin.form.active.false": "No",
	"companyAdmin.form.active.hint":
		"If disabled, the company will be inactive and inaccessible to its users.",
	"companyAdmin.form.ownerId": "Owner ID",
	"companyAdmin.form.foundedOn": "Founded On",
	"companyService.setEnabledById.statusTrue": "Activated",
	"companyService.setEnabledById.statusFalse": "Disabled",
	"companyService.setEnabledById.successMessage":
		"Company ${companyName} was ${status} successfully!",
	"userPreferencesSideGroup.title": "Preferences",
	"userPreferencesSideGroup.tabTitles.userProfileForm": "My Profile",
	"userPreferencesSideGroup.tabTitles.companyForm": "Preferences",
	"userCompanyForm.labels.contactMail": "Email",
	"userCompanyForm.labels.foundedOn": "Founded On",
	"userCompanyForm.labels.companyName": "Company (Name)",
	"userCompanyForm.labels.contactPhone": "Phone",
	"userCompanyForm.labels.contactWhatsApp": "WhatsApp",
	"userCompanyForm.placeholders.foundedOn": "Foundation Date",
	"userCompanyForm.placeholders.companyName": "Company Name",
	"userCompanyForm.placeholders.contactMail": "Company Email",
	"userCompanyForm.placeholders.contactPhone": "Company Phone",
	"userCompanyForm.placeholders.contactWhatsApp": "Company WhatsApp",
	"page.mailerAdminList.title": "Mailer Administration",
	"automailer.form.status.all": "All",
	"automailer.form.status.pending": "Pending",
	"automailer.form.status.processing": "Processing",
	"automailer.form.status.success": "Success",
	"automailer.form.status.error": "Error",
	"page.signup.steps.labels.name": "What's your name?",
	"page.signup.steps.labels.email":
		"What's your preferred email for contact?",
	"page.signup.steps.labels.birthday": "When were you born?",
	"page.signup.steps.labels.paymentMethod":
		"How would you prefer to make your payments?",
	"page.signup.steps.labels.confirm":
		"Great! Please check if your information is correct:",
	"page.signup.steps.labels.processing":
		"Just a moment, we're getting everything ready for you...",
	"page.signup.steps.labels.complete":
		"Done! Check your email to complete your registration.",
	"page.signup.steps.review.name": "Name: ${name}",
	"page.signup.steps.review.email": "Email: ${email}",
	"page.signup.steps.review.birthday": "Birthday: ${birthday}",
	"page.signup.steps.review.paymentMethod": "Payment Method: ${method}",
	"paymentType.label.boleto": "Invoice",
	"paymentType.label.creditCard": "Credit Card",
	"paymentType.label.debitCard": "Debit Card",
	"paymentType.label.pix": "PIX (Instant Payment)",
	"page.clientsList.onCompleteNewItemText":
		"The client ${clientName} has been created",
	"page.clientsList.onCompleteUpdateItemText":
		"The client ${clientName} has been updated",
	"page.clientsList.onDeleteItemText":
		"The client ${clientName} has been deleted",
	"page.clientsList.onSaveErrorText":
		"Failed to save client ${clientName}. Please try again.",
	"page.clientsList.onLoadErrorText":
		"Failed to load clients. Please refresh the page.",
	"page.clientsList.onCancelText": "Client changes were cancelled.",
	"page.clientsList.onDuplicateDocumentText":
		"A client with document ${documentId} already exists.",
	"page.brandsList.title": "Brands",
	"page.brandsList.onCompleteNewItemText":
		"The brand ${brandName} has been created",
	"page.brandsList.onCompleteUpdateItemText":
		"The brand ${brandName} has been updated",
	"page.brandsList.onDeleteItemText":
		"The brand ${brandName} has been deleted",
	"page.brandsList.onSaveErrorText":
		"Failed to save brand ${brandName}. Please try again.",
	"page.brandsList.onLoadErrorText":
		"Failed to load brands. Please refresh the page.",
	"page.brandsList.onCancelText": "Brand changes were cancelled.",
	"page.brandsList.onDuplicateNameText":
		"A brand with name ${brandName} already exists.",
	"page.suppliersList.title": "Suppliers",
	"page.suppliersList.onCompleteNewItemText":
		"The supplier ${supplierName} has been created",
	"page.suppliersList.onCompleteUpdateItemText":
		"The supplier ${supplierName} has been updated",
	"page.suppliersList.onDeleteItemText":
		"The supplier ${supplierName} has been deleted",
	"page.suppliersList.onSaveErrorText":
		"Failed to save supplier ${supplierName}. Please try again.",
	"page.suppliersList.onLoadErrorText":
		"Failed to load suppliers. Please refresh the page.",
	"page.suppliersList.onCancelText": "Supplier changes were cancelled.",
	"page.suppliersList.onDuplicateNameText":
		"A supplier with name ${supplierName} already exists.",
	"page.clientTables.title": "Tables",
	"page.clientTables.onCompleteNewItemText":
		"The table ${tableName} has been created",
	"page.clientTables.onCompleteUpdateItemText":
		"The table ${tableName} has been updated",
	"page.clientTables.onDeleteItemText":
		"The table ${tableName} has been deleted",
	"page.clientTables.onSaveErrorText":
		"Failed to save table ${tableName}. Please try again.",
	"page.clientTables.onLoadErrorText":
		"Failed to load tables. Please refresh the page.",
	"page.clientTables.onCancelText": "Table changes were cancelled.",
	"page.clientTables.onDuplicateNameText":
		"A table with name ${tableName} already exists.",
	"page.companiesList.title": "Companies (Admin)",
	"page.companiesList.onCompleteNewItemText":
		"The company ${companyName} has been created",
	"page.companiesList.onCompleteUpdateItemText":
		"The company ${companyName} has been updated",
	"page.companiesList.onDeleteItemText":
		"The company ${companyName} has been deleted",
	"page.companiesList.onSaveErrorText":
		"Failed to save company ${companyName}. Please try again.",
	"page.companiesList.onLoadErrorText":
		"Failed to load companies. Please refresh the page.",
	"page.companiesList.onCancelText": "Company changes were cancelled.",
	"page.companiesList.onDuplicateNameText":
		"A company with name ${companyName} already exists.",
	"page.productsList.title": "Products",
	"page.productsList.onCompleteNewItemText":
		"The product ${productName} has been created",
	"page.productsList.onCompleteUpdateItemText":
		"The product ${productName} has been updated",
	"page.productsList.onDeleteItemText":
		"The product ${productName} has been deleted",
	"page.productsList.onSaveErrorText":
		"Failed to save product ${productName}. Please try again.",
	"page.productsList.onLoadErrorText":
		"Failed to load products. Please refresh the page.",
	"page.productsList.onCancelText": "Product changes were cancelled.",
	"page.productsList.onDuplicateNameText":
		"A product with name ${productName} already exists.",
	"page.productsList.onDuplicateSuccessText":
		"The product ${productName} has been duplicated.",
	"page.stockList.title": "Products Stock",
	"page.stockList.onCompleteNewItemText":
		"The stock item ${stockName} has been created",
	"page.stockList.onCompleteUpdateItemText":
		"The stock item ${stockName} has been updated",
	"page.stockList.onDeleteItemText":
		"The stock item ${stockName} has been deleted",
	"page.stockList.onSaveErrorText":
		"Failed to save stock item ${stockName}. Please try again.",
	"page.stockList.onLoadErrorText":
		"Failed to load stock items. Please refresh the page.",
	"page.stockList.onCancelText": "Stock item changes were cancelled.",
	"page.stockList.onDuplicateNameText":
		"A stock item with name ${stockName} already exists.",
	"page.error.title": "Oops! Something went wrong.",
	"page.order.toast.error.loadOrders":
		"Failed to load orders. Please refresh the page.",
	"page.order.toast.error.saveOrder":
		"Failed to save order ${orderId}. Please try again.",
	"page.order.toast.success.createOrder":
		"Order ${orderId} has been created successfully.",
	"page.order.toast.success.updateOrder":
		"Order ${orderId} has been updated successfully.",
	"page.order.toast.success.deleteOrder":
		"Order ${orderId} has been deleted.",
	"page.order.toast.success.statusChange":
		"Order ${orderId} status changed to ${status}.",
	"page.order.toast.error.statusChange":
		"Failed to change order ${orderId} status. Please try again.",
	"page.order.toast.success.itemStatusChange":
		"Item ${itemName} status changed to ${status}.",
	"page.order.toast.error.itemStatusChange":
		"Failed to change item ${itemName} status. Please try again.",
	"page.order.toast.error.checkoutCreate":
		"Failed to create checkout for order ${orderId}.",
	"page.order.toast.success.checkoutCreate":
		"Checkout created successfully for order ${orderId}.",
	"page.order.toast.error.inventoryMovement":
		"Failed to update inventory for order ${orderId}.",
	"page.order.toast.success.inventoryMovement":
		"Inventory updated successfully for order ${orderId}.",
	"page.mailerAdminList.releaseAnnouncement.button": "Announce Release",
	"page.mailerAdminList.releaseAnnouncement.title": "Announce Release",
	"page.mailerAdminList.releaseAnnouncement.fields.version": "Version",
	"page.mailerAdminList.releaseAnnouncement.fields.recipients": "Recipients",
	"page.mailerAdminList.releaseAnnouncement.fields.message": "Message",
	"page.mailerAdminList.releaseAnnouncement.placeholders.recipients":
		"Enter recipients separated by commas",
	"page.mailerAdminList.releaseAnnouncement.placeholders.message":
		"Enter the message to be sent to the recipients",
	"page.mailerAdminList.releaseAnnouncement.success":
		"New version ${version} announced successfully!",
	"page.mailerAdminList.releaseAnnouncement.error":
		"Failed to announce new version ${version}. Please try again.",
	"component.companySelector.button.label": "Select Company",
	"component.companySelector.button.ariaLabel": "Open company selection menu",
	"component.companySelector.option.ariaLabel":
		"Select ${companyName} as active company",
	"page.mailerAdminList.releaseAnnouncement.subject":
		"BeeBase v${version} - Release Notes",
	"page.onboarding.title": "Activate Your Registration",
	"page.onboarding.description": "Please check your email to continue.",
	"page.onboarding.instructions.step0": "Enter the code received via email:",
	"page.onboarding.messages.error.invalidCode":
		"Invalid or used verification code. Please try again.",
	"page.onboarding.messages.error.expiredCode":
		"This verification code has expired. Please request a new one.",
	"page.onboarding.messages.error.generic":
		"An error occurred while validating your code. Please try again.",
	"page.onboarding.messages.success.validated":
		"Code validated successfully! Redirecting...",
	"page.onboarding.code.label": "Enter the code received by email",
	"page.onboarding.code.error.required": "Code is required",
	"page.onboarding.code.error.invalid": "Invalid or used code",
	"page.onboarding.code.success.validated":
		"Ok! Code was validated successfully.",
	"page.onboarding.password.title": "Set Your Password",
	"page.onboarding.password.description":
		"Please create a secure password for your account",
	"page.onboarding.password.label": "Set your Access Password",
	"page.onboarding.password.instructions":
		"Your password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character.",
	"page.onboarding.password.error.required": "Password is required",
	"page.onboarding.password.error.min":
		"Password must be at least 8 characters long",
	"page.onboarding.password.error.invalid":
		"Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
	"page.onboarding.messages.success.completed":
		"Account setup completed successfully!",
	"common.password.show": "Show password",
	"common.password.hide": "Hide password",
	"common.password.instructions":
		"Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&)."
};
