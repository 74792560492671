import React from "react";

import { DatePicker, IDatePickerProps } from "@fluentui/react";
import { appStateService } from "App";

export interface IDatePickerGroupProps extends IDatePickerProps {
	label?: string;
	value: Date | null;
	id: string;
	disabled?: boolean;
	dateTime?: boolean;
	onSelectDate?: (newDate: Date) => void;
	className?: string;
	placeholder?: string;
	required?: undefined | boolean;
}

/**
 * Re-usable DatePicker component, which wraps itself in a group
 *
 * @param props
 * @returns
 */
export const DatePickerGroup: React.FC<IDatePickerGroupProps> = (props) => {
	// TODO: Extract texts to the translator service
	const texts = {
		idNotInformed: "ID-não-informado",
		labelNotInformed: "Título-não-informado"
	};

	const resolvedLabel = props.label ?? texts.labelNotInformed;
	const resolvedId = props.id ?? texts.idNotInformed;

	return (
		<div>
			<label htmlFor={resolvedId}>{resolvedLabel}</label>
			<DatePicker
				id={resolvedId}
				value={
					undefined !== props?.value && props?.value !== null
						? props.value
						: null
				}
				formatDate={(date) =>
					!props?.dateTime
						? appStateService.format.date(date)
						: appStateService.format.dateTime(date)
				}
				disabled={props?.disabled ?? undefined}
				className={props?.className ?? undefined}
				onSelectDate={(newDate: Date | null | undefined) => {
					if (!newDate) {
						return;
					}

					const newDateCopy = new Date(newDate);

					if (!props?.dateTime) {
						// Sets 12PM as default time
						newDateCopy.setHours(12);
						newDateCopy.setMinutes(0);
						newDateCopy.setSeconds(0);
						newDateCopy.setMilliseconds(0);
					}

					if (typeof props.onSelectDate === "function") {
						props.onSelectDate(newDateCopy);
					}
				}}
				isRequired={props?.required ?? false}
				placeholder={props?.placeholder ?? undefined}
				// dateTimeFormatter={(date) => {
				// 	return appStateService.format.dateTime(date);
				// }}
				// onChange={(p1, p2) => props?.onChange()}
			/>
		</div>
	);
};
