/**
 * Application's Redux slice names
 */
export const ReduxSlices = {
	AppConfig: "appConfig",
	Clients: "clients",
	ClientOrders: "clientOrders",
	ClientTables: "clientTables",
	Companies: "companies",
	CompanyAdmin: "companyAdmin",
	UserProfile: "user",
	Products: "products",
	Categories: "categories",
	Suppliers: "suppliers",
	Brands: "brands",
	LandingPage: "landingPage",
	ClientOrderForm: "clientOrderForm",
	StockList: "stockList",
	// AI Slices
	AIChat: "aiChats",
	AIModelProcesses: "aiModelProcesses",
	Store: "store",
	StockMovement: "stockMovement",
	CompanyLinks: "companyLinks",
	Mailer: "mailer"
	// Thunks
	// UserActiveCompanyChangeThunk: "userActiveCompanyChange"
	// TODO: Cart: "cart",
	// TODO: Orders: "orders",
	// TODO: Admin: "admin"
};
