/**
 * Contact types enum.
 */
export enum ContactType {
	Phone = "phone",
	Email = "email",
	WhatsApp = "whatsapp",
	Instagram = "instagram",
	Facebook = "facebook",
	Telegram = "telegram"
}
