import { IAppProfile } from "./IAppProfile";
import { AppProfileNames } from "./AppProfileNames";
import { AppBasicPlanProfile } from "./AppBasicPlanProfile";
import { AppMasterPlanProfile } from "./AppMasterPlanProfile";
import { AppAdminProfile } from "./AppAdminProfile";

/**
 * The application profiles configuration.
 */
export const AppProfiles: {
	[AppProfileNames.adminProfileFull]: IAppProfile;
	[AppProfileNames.appBasicPlan]: IAppProfile;
	[AppProfileNames.appMasterPlan]: IAppProfile;
	[AppProfileNames.appNone]: IAppProfile;
} = {
	[AppProfileNames.adminProfileFull]: AppAdminProfile,
	[AppProfileNames.appBasicPlan]: AppBasicPlanProfile,
	[AppProfileNames.appMasterPlan]: AppMasterPlanProfile,
	[AppProfileNames.appNone]: {
		appModules: [],
		moduleActions: []
	}
};
