import { Routes } from "components/router/Routes";
import { ComponentPaths } from "components/router/ComponentPaths";
import { LazyLoad } from "components/router/LazyLoad";
import { RouteObject } from "react-router-dom";
import { AppModuleNames } from "./AppModules";
import { AuthLayerEnabledModule } from "./AuthLayerEnabled.module";

/**
 * The Stock Management module routes.
 *
 * @returns The module routes.
 */
export const StockManagementModule: () => RouteObject[] = () => {
	return AuthLayerEnabledModule(AppModuleNames.stockManagement, [
		{
			path: Routes.ProductStockList,
			element: LazyLoad(ComponentPaths.ProductStockPage),
			children: [
				{
					path: Routes.ProductStockEdit(),
					element: LazyLoad(ComponentPaths.ProductStockEditForm)
				}
			]
		},
		{
			path: Routes.StockMovementsList,
			element: LazyLoad(ComponentPaths.StockMovementsListPage)
		}
	]);
};
