import { AuthenticationLayer } from "./AuthLayer.modules";
import { RouteObject } from "react-router-dom";
import { AppModules } from "./AppModules";

/**
 * The Authentication and Module Active protected
 * module routes wrapper.
 *
 * @returns The production module routes.
 */
export const AuthLayerEnabledModule = (
	moduleName: string,
	routeConfigs: RouteObject[]
) => {
	const isModuleEnabled = AppModules?.[moduleName]?.enabled ?? false;

	const _routes: RouteObject[] = AuthenticationLayer(routeConfigs);

	return isModuleEnabled ? _routes : [];
};
