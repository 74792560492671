import React, { useCallback } from "react";
import { appStateService } from "App";
import { Toast } from "./Toast";
import { AppConfigSelectors } from "redux/selectors";
import { useSelector } from "react-redux";
import { MessageModalType } from "enums";

/**
 * Toast container component.
 * @returns The toast container component.
 */
export const ToastContainer: React.FC = () => {
	const toastConfig = useSelector(AppConfigSelectors.getToastConfig);

	const onDismiss = useCallback(() => {
		appStateService.appManager.dismissToast();
	}, []);

	return (
		<Toast
			message={
				toastConfig?.config?.message ?? ""
			}
			type={toastConfig?.config?.type ?? MessageModalType.info}
			onDismiss={onDismiss}
			open={toastConfig.open}
		/>
	);
};
