import { MailerService } from "./MailerService";
import { appStateService } from "App";

/**
 * Service responsible for sending version release announcements via email.
 */
export class ReleaseMailService extends MailerService {
	private static instance: ReleaseMailService;

	private constructor() {
		super();
	}

	public static getInstance(): ReleaseMailService {
		if (!ReleaseMailService.instance) {
			ReleaseMailService.instance = new ReleaseMailService();
		}
		return ReleaseMailService.instance;
	}

	/**
	 * Sends a version release announcement email.
	 * @param recipients Array of email addresses to receive the announcement
	 * @param version Version number being announced
	 * @param releaseNotes HTML content of the release notes
	 * @param subject Optional custom subject line
	 */
	public async sendReleaseAnnouncement(
		recipients: string | string[],
		version: string,
		releaseNotes: string,
		subject?: string
	): Promise<void> {
		await appStateService.functions.sendReleaseAnnouncement
			.get()
			.callService({
				recipients,
				version,
				content: releaseNotes,
				subject
			});
	}
}
