import { AppModuleNames, ModuleActionIds } from "components/modules";
import { AppProfileNames } from "components/modules/profiles/AppProfileNames";
import { MessageModalType } from "enums";
import { IdentifiedItem, IStoreProfileConfig } from "interfaces/index";
import { IToastSlice, toastInitialState } from "models/toast/Toast";

/**
 * AppConfig basic interface.
 */
interface IAppConfig extends IdentifiedItem {
	id: string;
	appBar: {
		hidden: boolean;
		title: string;
	};
	loader: {
		loading: boolean;
		loadingText: string;
	};
	messageModal: {
		type: MessageModalType;
		open: boolean;
		title: string;
		text: string;
	};
	toast: IToastSlice;
	companyProfile: {
		type: null | AppProfileNames;
		loading: boolean;
		loaded: boolean;
		modules: null | AppModuleNames[];
		moduleActions: null | ModuleActionIds[];
		config: IStoreProfileConfig;
	};
}

/**
 * AppConfig Redux slice interface.
 */
interface IAppConfigSlice extends IAppConfig {}

/**
 * AppConfig factory class.
 */
class AppConfig implements IAppConfig {
	id = new Date().getTime().toString();
	appBar = {
		hidden: false,
		title: ""
	};
	loader = {
		loading: false,
		loadingText: ""
	};
	messageModal = {
		type: MessageModalType.info,
		open: false,
		title: "",
		text: ""
	};
	toast = { ...toastInitialState };
	companyProfile = {
		type: null,
		loading: false,
		loaded: false,
		modules: null,
		moduleActions: null,
		config: {
			storeTitle: "",
			storeName: ""
		}
	};

	constructor(data?: undefined | IAppConfig) {
		this.id = data?.id ?? this.id;
		this.appBar = {
			hidden: data?.appBar?.hidden ?? false,
			title: data?.appBar?.title ?? ""
		};
		this.loader = {
			loading: data?.loader?.loading ?? false,
			loadingText: data?.loader?.loadingText ?? ""
		};
		this.messageModal = {
			type: data?.messageModal?.type ?? MessageModalType.info,
			open: data?.messageModal?.open ?? false,
			title: data?.messageModal?.title ?? "",
			text: data?.messageModal?.text ?? ""
		};
		this.toast = {
			config: data?.toast?.config ?? toastInitialState.config,
			open: data?.toast?.open ?? toastInitialState.open
		};
		this.companyProfile = {
			type: data?.companyProfile?.type ?? null,
			loading: data?.companyProfile?.loading ?? false,
			loaded: data?.companyProfile?.loaded ?? false,
			modules: data?.companyProfile?.modules ?? null,
			moduleActions: data?.companyProfile?.moduleActions ?? null,
			config: {
				storeTitle: data?.companyProfile?.config?.storeName ?? "",
				storeName: data?.companyProfile?.config?.storeName ?? ""
			}
		};
	}
}

/**
 * User profile initial state.
 */
const appConfigInitialState: IAppConfigSlice = {
	id: "",
	appBar: {
		hidden: false,
		title: ""
	},
	loader: {
		loading: false,
		loadingText: ""
	},
	messageModal: {
		type: MessageModalType.info,
		open: false,
		title: "N.D.",
		text: "N.D."
	},
	toast: { ...toastInitialState },
	companyProfile: {
		type: null,
		loading: false,
		loaded: false,
		modules: null,
		moduleActions: null,
		config: {
			storeTitle: "",
			storeName: ""
		}
	}
};

export { IAppConfig, AppConfig, IAppConfigSlice, appConfigInitialState };
