import React, { useCallback, useEffect } from "react";

import { AppRouter } from "components/router/AppRouter";
import { Header } from "components/shared/header/Header";
import { HeaderSpacer } from "components/shared/header";
import { IndependentComponents } from "components/shared/layer";
import { configAppModules } from "components/modules";
import { useActiveCompanyWrapper } from "hooks/useActiveCompanyWrapper";
import { useSelector } from "react-redux";
import { AppConfigSelectors, UserSelectors } from "redux/selectors";
import { GiftBoxOpenIcon } from "components/shared/icon";
import { appStateService } from "App";

import "./AppLayout.scss";

interface IRouterFrame {
	children: React.ReactNode;
}

/**
 * Header spacer component
 */
function RouterFrame(props: IRouterFrame) {
	return <div className="router-frame">{props.children}</div>;
}

/**
 * Renders the basic structure of the React application,
 * Notice the className defines layout major styles, from imported AppLayout.css.
 */
export function AppLayout() {
	// const [companyHasChanged, setCompanyHasChanged] = useState(false);
	// const companiesList = useSelector(CompanySelectors.getCompanies);

	const authenticated = useSelector(UserSelectors.selectAuthenticated);
	const profileLoaded = useSelector(
		AppConfigSelectors.getCompanyProfileLoaded
	);
	const profileLoading = useSelector(
		AppConfigSelectors.getCompanyProfileLoading
	);

	const loadProfile = useCallback(
		function () {
			// debugger;
			if (!profileLoaded && !profileLoading) {
				configAppModules();
				// setCompanyHasChanged(false);
			}
		},
		[profileLoaded, profileLoading]
	);

	const unloadProfile = useCallback(() => {
		// configAppModules();
		// setCompanyHasChanged(true);
		// loadProfile();
		appStateService.appManager.setLoading(false);
		appStateService.appManager.setProfileLoaded(false);
		// debugger;
	}, []);

	// Configures the access to the Routes / Actions in the app
	useActiveCompanyWrapper(unloadProfile);

	useEffect(() => {
		loadProfile();

		return () => {
			// appStateService.appManager.setLoading(false);
		};
	}, [loadProfile]);

	useEffect(() => {
		return () => {
			// if (companyHasChanged) setCompanyHasChanged(false);
			// // appStateService.appManager.setProfileLoaded(false);
			// appStateService.appManager.setProfileLoading(false);
			// appStateService.appManager.setLoading(false);
		};
	}, []);

	return profileLoaded ? (
		<div className="App">
			{/* Begin: Menu - Fixed part of the app */}
			<Header />
			{/* End: Menu - Fixed part of the app */}
			{/* Begin: Moving part of the app */}
			<HeaderSpacer />
			<RouterFrame>
				<AppRouter />
			</RouterFrame>
			{/* End: Moving part of the app */}
			{/* Begin: Grouping free components in the app */}
			<IndependentComponents />
			{/* End: Grouping free components in the app */}
		</div>
	) : (
		<div className="App-loading">
			<div className="loading-icon">
				<GiftBoxOpenIcon />
			</div>
			<div className="loading-text">
				{authenticated
					? "Carregando Perfil da Empresa"
					: "Inicializando"}
			</div>
		</div>
	);
}
