import { createSelector } from "@reduxjs/toolkit";
import { IProduct } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Product.
 */
export const ProductSelectors = {
	getProducts: (state: RootState) => state.products.list,
	getProductByIds: createSelector(
		(state: RootState) => state.products.list,
		(state: RootState, itemIds: string[]) => itemIds,
		(products: IProduct[], itemIds: string[]) =>
			products?.filter((product) => itemIds.includes(product.id)) ?? []
	),
	getProductById: createSelector(
		(state: RootState) => state.products.list,
		(state: RootState, itemId: string) => itemId,
		(products: IProduct[], itemId: string) =>
			products.find((product) => product.id === itemId)
	),
	getProductsLoading: (state: RootState) => state.products.loading,
	getProductsQueried: (state: RootState) => state.products.queried,
	getIsEditing: (state: RootState) => state.products.editing,
	getProductsEditing: (state: RootState) => state.products.editingList,
	getProductsInStockIds: createSelector(
		(state: RootState) => state.products.inStockIds,
		(inStockIds) => Object.keys(inStockIds)
	),
	getProductInStock: createSelector(
		(state: RootState) => Object.keys(state.products.inStockIds),
		(_: RootState, productId: string) => productId,
		(inStockProductIds, productId): boolean =>
			inStockProductIds.includes(productId)
	),
	getProductStockEntryIds: createSelector(
		(state: RootState) => state.products.inStockIds,
		(_: RootState, productId: string) => productId,
		(state, productId): string[] => {
			const inStockProductIds = Object.keys(state);
			return inStockProductIds.includes(productId)
				? state[productId]
				: [];
		}
	)
	// getProductsError: (state: RootState) => state.products.error
};
