import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { appStateService } from "App";
import { UserSelectors } from "redux/selectors";
import { useTranslate, useTranslateFn } from "hooks/i18n";
import { Overlay } from "components/shared/overlay";
import { AppBarTestIds } from "static/test";
import { Dropdown, IOption } from "components/shared/dropdown";
import { IDropdownOption } from "@fluentui/react";
import { CompanySelectors } from "redux/selectors/companies/companiesSelectors";
import { Routes } from "components/router/Routes";
import { useNavigate } from "react-router-dom";
import { useActiveCompanyWrapper } from "hooks/useActiveCompanyWrapper";

import "./UserProfileButton.scss";
import { CopyContentWrapper } from "components/shared/util";
import { GlobalNavButtonIcon } from "components/shared/icon";
import { IconSize } from "components/shared/icon/shared/interfaces";

const packageJSON = require("../../../../../package.json");

/**
 * Contract for the properties of the UserProfileButton component.
 */
interface IUserProfileProps {}

/**
 * Component for organizing the user profile button experience.
 *
 * @param props
 */
const UserProfileButton: React.FC<IUserProfileProps> = (props) => {
	const translate = useTranslateFn();
	const strings = {
		appName: translate("app.name"),
		img: {
			profile: "Foto de perfil de ",
			profileAlt: "Foto de perfil de "
		},
		appVersion: {
			tag: `Versão: ${packageJSON.version}`,
			tooltip: "Copiar versão do Aplicativo"
		},
		companySwitch: "Trocar de Empresa",
		userProfile: "Perfil de Usuário",
		signOut: "Sair",
		configurations: "Configurações do Aplicativo"
	};
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const navigate = useNavigate();
	const companySvc = useActiveCompanyWrapper(() =>
		appStateService.company.service.get()
	);
	const user = useSelector(UserSelectors.selectUserProfile);
	const companies = useSelector(CompanySelectors.getCompanies);
	const appConfirmLogoffText = useTranslate("app.bar.button.logoff.confirm");
	const userProfileButtonText = useTranslate(
		"global.userProfile.button.profile"
	);
	const signOutButtonText = useTranslate("global.userProfile.button.signOut");

	const getCompanyName = useCallback(
		(companyId: string) => {
			const company =
				!companies || companies.length === 0
					? null
					: companies.find((c) => c && c.id === companyId);

			return null !== company ? company?.name : companyId;
		},
		[companies]
	);

	const companyOptions = useMemo(
		() =>
			user?.companies?.map(
				(company) =>
					({
						text: getCompanyName(company),
						key: company
					} as IDropdownOption<IOption>)
			) ?? [],
		[user, getCompanyName]
	);

	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	function onClickUserProfileButton(
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) {
		event.preventDefault();

		appStateService.user.setProfileFormOpen(true);
		toggleDropdown();
	}

	async function onClickSignOutButton(
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) {
		event.preventDefault();

		// TODO: Improve this to use the ConfirmDialog component
		if (window.confirm(appConfirmLogoffText)) {
			await appStateService.auth.logOff();
			// navigate(Routes.Login);
		}
	}

	/**
	 * Handles the company switch event.
	 *
	 * @param event
	 * @param option
	 */
	async function onCompanySwitch(
		event: React.FormEvent<HTMLDivElement>,
		option: IDropdownOption<IOption> | IDropdownOption<IOption>[]
	) {
		const selectedOption = Array.isArray(option) ? option[0] : option;
		const changingId = selectedOption.key.toString();

		if (changingId === user.activeCompany) {
			return;
		}

		await appStateService.user.setActiveCompany(changingId);

		// Redirect the user to the Company's homepage
		navigate(Routes.Home);
	}

	// Ensure the user companies are loaded for the display in the dropdown
	useEffect(() => {
		if (companySvc) companySvc.ensureLoadedUserCompanies();
	}, [companySvc]);

	return (
		<div className="user-profile">
			<button
				onClick={toggleDropdown}
				className="profile-button"
				data-tid={AppBarTestIds.UserProfileButton}
				aria-label={strings.configurations}
				title={strings.configurations}
			>
				{user.pictureURL ? (
					<img
						src={user.pictureURL}
						alt={`${strings.img.profileAlt} ${user.name}`}
						title={`${strings.img.profileAlt} ${user.name}`}
						className="profile-pic"
					/>
				) : (
					<GlobalNavButtonIcon size={IconSize.MD} />
				)}
			</button>
			{isDropdownOpen && (
				<Overlay open={isDropdownOpen} onClick={toggleDropdown}>
					<div
						data-tid={AppBarTestIds.ContextMenu}
						className="dropdown"
					>
						<button
							onClick={(event) => onClickUserProfileButton(event)}
						>
							{userProfileButtonText}
						</button>
						<div
							className="combo-box-padding"
							// data-tid={AppBarTestIds.UserLogoutButton}
						>
							<Dropdown
								hideEmptyOption
								selectedKey={user.activeCompany ?? ""}
								onChange={(event, option) =>
									onCompanySwitch(event, option)
								}
								options={companyOptions}
								disabled={companyOptions?.length === 0}
								listName={strings.companySwitch}
							/>
						</div>
						{/* <button
							onClick={(event) => onClickSignOutButton(event)}
							data-tid={AppBarTestIds.UserLogoutButton}
						>
							{signOutButtonText}
						</button> */}
						<div className="dropdown-info">
							<div>
								<strong>{strings.appName}</strong>
							</div>
							<CopyContentWrapper
								content={strings.appVersion.tag}
								tooltip={strings.appVersion.tooltip}
							/>
						</div>
						<div className="dropdown-divider"></div>
						<button
							onClick={(event) => onClickSignOutButton(event)}
							data-tid={AppBarTestIds.UserLogoutButton}
							aria-label={strings.signOut}
							title={strings.signOut}
						>
							{signOutButtonText}
						</button>
					</div>
				</Overlay>
			)}
		</div>
	);
};

export { UserProfileButton, IUserProfileProps };
