import { AutoMailStatus } from "enums";
import { IFirebaseItem } from "interfaces/index";
import { ISliceList } from "interfaces/slices";

/**
 * Defines the contract of the AutoMailMessageConfig.
 */
export interface IAutoMailMessageConfig {
	subject: string;
	text: string;
	html: string;
}

/**
 * Defines the contract of the AutoMailEntry.
 */
export interface IAutoMailEntry extends IFirebaseItem {
	to: string[];
	cc: string[];
	bcc: string[];
	message: IAutoMailMessageConfig;
	delivery: {
		state: "" | AutoMailStatus;
	};
}

/**
 * Defines the generator class of the AutoMailEntry.
 */
export class AutoMailEntry implements IAutoMailEntry {
	id: string;
	to: string[];
	cc: string[];
	bcc: string[];
	message: IAutoMailMessageConfig;
	delivery: {
		state: "" | AutoMailStatus;
	};
	deleted: boolean;
	timestamp: any;

	constructor(
		id: string,
		to: string[],
		cc: string[],
		bcc: string[],
		message: IAutoMailMessageConfig,
		delivery: {
			state: "" | AutoMailStatus;
		},
		deleted: boolean,
		timestamp: any
	) {
		this.id = id;
		this.to = to;
		this.cc = cc;
		this.bcc = bcc;
		this.message = message;
		this.delivery = delivery ?? { state: AutoMailStatus.Pending };
		this.deleted = deleted;
		this.timestamp = timestamp;
	}
}

/**
 * Defines the contract of the MailerSlice.
 */
export interface IMailerSlice extends ISliceList<IAutoMailEntry> {}

/**
 * Initial state for the MailerSlice.
 */
export const mailerInitialState: IMailerSlice = {
	list: [],
	loading: false,
	queried: false,
	editing: false,
	editingList: []
};
