import { AutoMailStatus } from "enums";
import { Timestamp } from "firebase/firestore";
import { IAutoMailEntry, IAutoMailMessageConfig } from "models/index";
import { actions } from "redux/reducers/mailer/reducer";
import { ReduxStoreService } from "services/redux";

const _listName = "automailer";

/**
 * Mailer service generator class.
 */
export class MailerService extends ReduxStoreService<IAutoMailEntry> {
	constructor() {
		super(
			_listName,
			actions.setList,
			actions.setQueried,
			actions.setLoading
		);
	}

	/**
	 * Sends an email using automated resources from
	 * "automailer".
	 *
	 * @param to List of recipients
	 * @param cc List of carbon copy recipients
	 * @param bcc List of blind carbon copy recipients
	 * @param message Message configuration
	 */
	async sendMail(
		to: string[],
		cc: string[],
		bcc: string[],
		message: IAutoMailMessageConfig
	) {
		await this.createItem(
			{
				id: "",
				to,
				cc,
				bcc,
				message,
				timestamp: Timestamp.now(),
				delivery: { state: AutoMailStatus.Pending }
			},
			(item) => {
				// TODO: Notify the user that the mail was sent
			}
		);
	}
}

export const MailerServiceInstance = new MailerService();
