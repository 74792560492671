import { ICategory } from "models/index";
import { actions } from "redux/reducers/categories/reducer";
import { CompanySegment } from "services/companies/CompanySegment";
// import { FirebaseServiceHandler } from "services/firebase/FirebaseServiceHandler";

const _baseStoragePath = (brandId: string) => `categories/${brandId}`;

/**
 * Class for managing the basics of the Category data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyCategoryService extends CompanySegment<ICategory> {
	constructor(companyId: string) {
		super(companyId, "categories", actions.setList, actions.setQueried);
	}

	/**
	 * Updates the item in the database, setting is "enabled" property to the given value.
	 * The item is updated via its ID.
	 *
	 * @param id The ID of the item to be updated.
	 * @param enabled The value to be set to the "enabled" property.
	 * @param onUpdated [Optional] The callback to be executed after the item is updated.
	 */
	async setEnabledById(
		id: string,
		enabled: boolean,
		onUpdated?: undefined | ((item: ICategory) => void | Promise<void>)
	): Promise<void> {
		const item = await this.getItemById(id);
		await this.updateItem({ ...item, enabled });
	}
}

export { CompanyCategoryService };
