import React from "react";
import { Icon } from "./Icon";
import { IIconDerivateProps } from "./shared/interfaces";

/**
 * Fluent UI's Custom List icon component.
 *
 * @param props The properties of the component.
 * @returns JSX.Element
 */
export const CustomListIcon: React.FC<IIconDerivateProps> = (props) => {
	return <Icon name="CustomList" {...props} />;
};
