/**
 * Supported payment methods in the platform.
 */
export enum PaymentMethod {
	creditCard = "creditCard",
	debitCard = "debitCard",
	googlePay = "googlePay",
	picPay = "picPay",
	pix = "pix",
	cash = "cash",
	boleto = "boleto"
}
