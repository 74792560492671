import { translate } from "hooks/i18n";
import { IAppManagerService, IStoreProfileConfig } from "interfaces";
// import { ReduxStoreService } from "./redux";
import { store } from "App";
import { actions } from "redux/reducers/appConfig/reducer";
import { MessageModalType } from "enums";
import { AppModuleNames, ModuleActionIds } from "components/modules";
import { AppProfileNames } from "components/modules/profiles/AppProfileNames";

let _confirmCallback: undefined | (() => void) = undefined;
let _cancelCallback: undefined | (() => void) = undefined;

/**
 * Implements the application mainstream service layer.
 * Shared services are microservices of this class.
 * For instance:
 * * Message modal behaviors.
 * * Application general behaviors.
 */
export class AppManagerService implements IAppManagerService {
	/**
	 * Sets the Browser (navigator) top bar title based on title in the header tag of the page.
	 */
	setAppTitle(title: string): Promise<void> {
		window.document.title = title;

		return;
	}

	/**
	 * Sets the Confirm Callback,
	 * when dealing with the Modal options.
	 */
	setConfirmCallback(callback: null | (() => void)): void {
		_confirmCallback = callback ?? undefined;
	}

	/**
	 * Sets the Cancel Callback,
	 * when dealing with the Modal options.
	 */
	setCancelCallback(callback: null | (() => void)): void {
		_cancelCallback = callback ?? undefined;
	}

	/**
	 * Clears the Message callbacks specified in these local instances
	 */
	clearMessageCallbacks(): void {
		_confirmCallback = undefined;
		_cancelCallback = undefined;
	}

	/**
	 * Shows a modal message of type "info".
	 *
	 * @param message The message to be shown.
	 * @param title [Optional] The title of the message.
	 */
	showMessage(
		message: string,
		type: MessageModalType = MessageModalType.info,
		title?: undefined | string
	): Promise<void> {
		const titleTranslated = translate(
			!title ? "global.modal.message.default.title" : title
		);
		const messageTranslated = translate(message);

		this.setMessageModalType(type);
		this.setMessageModalTitle(titleTranslated);
		this.setMessageModalText(messageTranslated);
		this.setMessageModalOpen(true);

		return Promise.resolve();
	}

	/**
	 * Shows a modal message of type "info".
	 *
	 * @param message The message to be shown.
	 * @param title [Optional] The title of the message.
	 */
	showInfo(message: string, title?: undefined | string): Promise<void> {
		this.showMessage(message, MessageModalType.info, title);

		return Promise.resolve();
	}

	/**
	 * Shows a modal message of type "warning".
	 *
	 * @param message The message to be shown.
	 * @param title [Optional] The title of the message.
	 */
	showWarning(message: string, title?: undefined | string): Promise<void> {
		this.showMessage(message, MessageModalType.warning, title);

		return Promise.resolve();
	}

	/**
	 * Shows a modal message of type "error".
	 *
	 * @param message The message to be shown.
	 * @param title [Optional] The title of the message.
	 */
	showError(message: string, title?: undefined | string): Promise<void> {
		this.showMessage(message, MessageModalType.error, title);

		return Promise.resolve();
	}

	/**
	 * Shows a modal message of type "success".
	 *
	 * @param message The message to be shown.
	 * @param title [Optional] The title of the message.
	 */
	showSuccess(message: string, title?: undefined | string): Promise<void> {
		this.showMessage(message, MessageModalType.success, title);

		return Promise.resolve();
	}

	/**
	 * Hides a modal message.
	 */
	hideMessage(): Promise<void> {
		this.setMessageModalOpen(false);

		return Promise.resolve();
	}

	/**
	 * Toggles the visibility of the app bar.
	 */
	toggleAppBar(): void {
		// inverts the state of the appBar.Hidden property
		store.dispatch(
			actions.setAppBarHidden(!store.getState().appConfig.appBar.hidden)
		);
	}

	/**
	 * Hides the app bar (set hidden to true).
	 */
	hideAppBar(): void {
		store.dispatch(actions.setAppBarHidden(true));
	}

	/**
	 * Shows the app bar (set hidden to false).
	 */
	showAppBar(): void {
		store.dispatch(actions.setAppBarHidden(false));
	}

	/**
	 * Sets the Title within the boundaries of the app bar.
	 */
	setAppBarTitle(title: string): void {
		store.dispatch(actions.setAppBarTitle(title));
	}

	/**
	 * Sets the loading state of the App Config.
	 *
	 * @param status The loading state.
	 */
	setLoading(status: boolean): void {
		store.dispatch(actions.setLoading(status));
	}

	/**
	 * Shows the loading state of the App Config.
	 */
	showLoader(): void {
		this.setLoading(true);
	}

	showConfirm(
		title: string,
		message: string,
		onConfirm: () => void | Promise<void>,
		onCancel?: undefined | (() => void)
	): Promise<void> | void {
		// Sets the modal type to confirm (default)
		this.setMessageModalType(MessageModalType.info);

		this.setMessageModalTitle(title);
		this.setMessageModalText(message);
		this.setMessageModalOpen(true);
		this.setConfirmCallback(onConfirm);

		if (undefined !== onCancel) {
			this.setCancelCallback(onCancel);
		}

		return Promise.resolve();
	}

	/**
	 * Hides the loading state of the App Config.
	 */
	hideLoader(): void {
		this.setLoading(false);
		this.resetLoader();
	}

	/**
	 * Sets the loading text of the App Config.
	 *
	 * @param text The loading text.
	 */
	setLoadingText(text: string): void {
		store.dispatch(actions.setLoadingText(text));
	}

	/**
	 * Resets the loading text of the App Config.
	 */
	resetLoader(): void {
		this.setLoading(false);
		this.setLoadingText("");
	}

	/**
	 * Sets the message modal type.
	 *
	 * @param type The message modal type.
	 */
	setMessageModalType(type: MessageModalType): void {
		store.dispatch(actions.setMessageModalType(type));
	}

	/**
	 * Sets whether the App's Message Modal is open.
	 *
	 * @param text The loading text.
	 */
	setMessageModalOpen(open: boolean): void {
		store.dispatch(actions.setMessageModalOpen(open));
	}

	/**
	 * Sets the message title of the App Config.
	 *
	 * @param text The message title.
	 */
	setMessageModalTitle(title: string): void {
		store.dispatch(actions.setMessageModalTitle(title));
	}

	/**
	 * Sets the message text of the App Config.
	 *
	 * @param text The message text.
	 */
	setMessageModalText(text: string): void {
		store.dispatch(actions.setMessageModalText(text));
	}

	/**
	 * Gets whether the Confirm callback of the App Message Modal was set.
	 */
	hasConfirmCallback(): boolean {
		return (
			_confirmCallback !== undefined &&
			_confirmCallback !== null &&
			typeof _confirmCallback === "function"
		);
	}

	/**
	 * Gets whether the Cancel callback of the App Message Modal was set.
	 */
	hasCancelCallback(): boolean {
		return (
			_cancelCallback !== undefined &&
			_cancelCallback !== null &&
			typeof _cancelCallback === "function"
		);
	}

	/**
	 * Invokes the Confirm Callback,
	 * when dealing with the Modal options.
	 * Self-clears, so it can be used only once.
	 */
	onConfirm(): void {
		if (this.hasConfirmCallback()) {
			_confirmCallback();
			_confirmCallback = null;
		}
	}

	/**
	 * Invokes the Cancel Callback,
	 * when dealing with the Modal options.
	 * Self-clears, so it can be used only once by invoke.
	 */
	onCancel(): void {
		if (this.hasCancelCallback()) {
			_cancelCallback();
			_cancelCallback = null;
		}
	}

	/**
	 * Sets the Company Profile configuration.
	 *
	 * @param modules The company profile type.
	 */
	setProfileConfig(modules: null | IStoreProfileConfig): void {
		store.dispatch(actions.setCompanyProfileConfig(modules));
	}

	/**
	 * Sets the Company Profile Modules List loaded.
	 *
	 * @param type The company profile type.
	 */
	setProfileConfigModules(modules: null | AppModuleNames[]): void {
		store.dispatch(actions.setCompanyProfileModules(modules));
	}

	/**
	 * Sets the Company Profile Module Actions List.
	 *
	 * @param type The company profile type.
	 */
	setProfileConfigModuleActions(
		moduleActions: null | ModuleActionIds[]
	): void {
		store.dispatch(actions.setCompanyProfileModuleActions(moduleActions));
	}

	/**
	 * Sets the Company Profile type.
	 *
	 * @param type The company profile type.
	 */
	setProfileType(type: null | AppProfileNames): void {
		store.dispatch(actions.setCompanyProfileType(type));
	}

	/**
	 * Sets the Company Profile loading state.
	 *
	 * @param loading The company profile loading state.
	 */
	setProfileLoading(loading: boolean): void {
		store.dispatch(actions.setCompanyProfileLoading(loading));
	}

	/**
	 * Sets the Company Profile loaded state.
	 *
	 * @param loaded The company profile loaded state.
	 */
	setProfileLoaded(loaded: boolean): void {
		store.dispatch(actions.setCompanyProfileLoaded(loaded));
	}

	/**
	 * Gets the Company Profile type.
	 */
	getProfileType(): null | AppProfileNames {
		return store.getState().appConfig.companyProfile.type;
	}

	/**
	 * Gets the Company Profile loading state.
	 */
	getProfileLoading(): boolean {
		return store.getState().appConfig.companyProfile.loading;
	}

	/**
	 * Gets the Company Profile loaded state.
	 */
	getProfileLoaded(): boolean {
		return store.getState().appConfig.companyProfile.loaded;
	}

	/**
	 * Gets the Company Profile Modules List.
	 */
	getProfileModules(): null | AppModuleNames[] {
		return store.getState().appConfig.companyProfile.modules;
	}

	/**
	 * Gets the Company Profile Module Actions List.
	 */
	getProfileModuleActions(): null | ModuleActionIds[] {
		return store.getState().appConfig.companyProfile.moduleActions;
	}

	/**
	 * Gets the Company Profile Configuration.
	 */
	getProfileConfig(): IStoreProfileConfig {
		return store.getState().appConfig.companyProfile.config;
	}

	/**
	 * Shows a toast message.
	 *
	 * @param message The message to be shown.
	 * @param type The message type.
	 */
	showToast(message: string, type: MessageModalType = MessageModalType.info) {
		store.dispatch(actions.showToast({ message, type }));
	}

	/**
	 * Shows a success toast message.
	 *
	 * @param message The message to be shown.
	 */
	showSuccessToast(message: string): void {
		this.showToast(message, MessageModalType.success);
	}

	/**
	 * Shows an error toast message.
	 *
	 * @param message The message to be shown.
	 */
	showErrorToast(message: string): void {
		this.showToast(message, MessageModalType.error);
	}

	/**
	 * Shows an info toast message.
	 *
	 * @param message The message to be shown.
	 */
	showInfoToast(message: string): void {
		this.showToast(message, MessageModalType.info);
	}

	/**
	 * Shows a warning toast message.
	 *
	 * @param message The message to be shown.
	 */
	showWarningToast(message: string): void {
		this.showToast(message, MessageModalType.warning);
	}

	/**
	 * Dismisses the toast message displaying.
	 */
	dismissToast() {
		store.dispatch(actions.dismissToast());
	}
}
