import React, { useEffect, useRef } from "react";
import { Icon } from "components/shared/icon";
import { useTranslateFn } from "hooks/i18n";
import { useSelector } from "react-redux";
import { CompanySelectors } from "redux/selectors";
import { appStateService } from "App";
import { ICompany } from "models";

import "./CompanySelector.scss";

/**
 * @interface ICompanySelectorProps
 * @property {string} activeCompanyId - The ID of the active company.
 */
interface ICompanySelectorProps {
	activeCompanyId: string;
}

/**
 * @component CompanySelector
 * @description Component for company selection.
 * @param {ICompanySelectorProps} props - The component properties.
 * @returns {React.ReactNode} The CompanySelector component.
 */
export const CompanySelector: React.FC<ICompanySelectorProps> = ({
	activeCompanyId
}) => {
	const translate = useTranslateFn();
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
	const companies = useSelector(CompanySelectors.getCompanies);
	const activeCompany = companies.find(
		(company) => company.id === activeCompanyId
	);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsDropdownOpen(false);
			}
		};

		if (isDropdownOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isDropdownOpen]);

	const handleCompanySelect = async (company: ICompany) => {
		try {
			await appStateService.user.setActiveCompany(company.id);
			setIsDropdownOpen(false);
		} catch (error) {
			appStateService.appManager.showError(error);
		}
	};

	return (
		<div className="company-selector-container" ref={dropdownRef}>
			<button
				className="company-selector-button"
				onClick={() => setIsDropdownOpen(!isDropdownOpen)}
				aria-label={translate(
					"component.companySelector.button.ariaLabel"
				)}
				aria-expanded={isDropdownOpen}
				aria-haspopup="true"
			>
				<span className="company-name">
					{activeCompany?.name ||
						translate("component.companySelector.button.label")}
				</span>
				<Icon
					name="ChevronDown"
					style={{
						transform: `rotate(${
							isDropdownOpen ? "180deg" : "0deg"
						})`,
						transition: "transform 0.2s ease"
					}}
				/>
			</button>
			{isDropdownOpen && (
				<div className="company-selector-dropdown">
					{companies.map((company) => (
						<button
							key={company.id}
							className={`company-option ${
								company.id === activeCompanyId ? "active" : ""
							}`}
							onClick={() => handleCompanySelect(company)}
							aria-label={translate(
								"component.companySelector.option.ariaLabel",
								{ companyName: company.name }
							)}
						>
							{company.name}
						</button>
					))}
				</div>
			)}
		</div>
	);
};
