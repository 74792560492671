/**
 * Enum for client data's Document Type
 */
export enum PersonDocumentType {
	None = "Nenhum",
	CPF = "cpf",
	CNH = "cnh",
	RG = "rg",
	CNPJ = "cnpj"
}
