import { useEffect, useMemo, useRef, useState } from "react";
import { ModuleActionIds, ModuleActions } from "components/modules";

const _buttonCheckTime = 2500; // milissegundos

/**
 * Contract fot the Hook to check if the user action access.
 */
export interface IUseProtectedActionHook {
	canUse: boolean;
	isChecking: boolean;
}

/**
 * Hook to check if the user has access to a specific action.
 */
export const useProtectedAction = (
	actionId: null | ModuleActionIds
): IUseProtectedActionHook => {
	const checkerIntervalRef = useRef<null | NodeJS.Timeout>(null);
	const [checkTime, setCheckTime] = useState<number>(0);
	const hasActionId = useMemo(() => !!actionId, [actionId]);
	const isProtectionFlagged = useMemo(() => {
		return (
			hasActionId &&
			actionId.length > 0 &&
			checkTime &&
			!ModuleActions.canPerformAction(actionId)
		);
	}, [hasActionId, checkTime, actionId]);

	useEffect(() => {
		if (!checkerIntervalRef.current && hasActionId) {
			checkerIntervalRef.current = setInterval(() => {
				setCheckTime(Date.now());
			}, _buttonCheckTime);
		}

		return () => {
			if (checkerIntervalRef.current) {
				clearInterval(checkerIntervalRef.current);
			}
		};
	}, []);

	return {
		canUse: !hasActionId || (checkTime > 0 && !isProtectionFlagged),
		isChecking: hasActionId && !checkTime
	};
};
