import { appStateService } from "App";
import { AppModuleNames } from "../AppModules";

/**
 * The module action IDs,
 * enumerated.
 */
export enum ModuleActionIds {
	brandsListView = "brandsListView",
	orderProductionModeView = "orderProductionModeView"
}

/**
 * The module action.
 */
export interface IModuleAction {
	/**
	 * The module name.
	 */
	moduleName: AppModuleNames;
	/**
	 * The disabled state.
	 */
	disabled: boolean;
}

/**
 * The module action maps.
 */
export interface IModuleActionMaps {
	[ModuleActionIds.brandsListView]: IModuleAction;
	[ModuleActionIds.orderProductionModeView]: IModuleAction;
	canPerformAction: (actionId: string) => boolean;
	applyProfile: (userActionsEnabled: ModuleActionIds[]) => void;
}

/**
 * The module actions mapping object.
 */
export const ModuleActions: IModuleActionMaps = {
	[ModuleActionIds.brandsListView]: {
		moduleName: AppModuleNames.orderProduction,
		disabled: false
	},
	[ModuleActionIds.orderProductionModeView]: {
		moduleName: AppModuleNames.orderProduction,
		disabled: false
	},
	/**
	 * Check if the action can be performed by the user.
	 *
	 * @param actionId The action ID.
	 * @returns Default to true, if not disallowed, it will allow passing.
	 */
	canPerformAction: (actionId: string) => {
		return !ModuleActions[actionId]?.disabled;
	},
	/**
	 * Apply the user profile.
	 *
	 * @param userActionsEnabled The user actions enabled.
	 */
	applyProfile: (userActionsEnabled: ModuleActionIds[]) => {
		if (
			userActionsEnabled.length === 0 ||
			appStateService.appManager.getProfileLoaded()
		) {
			return;
		}

		Object.keys(ModuleActionIds).forEach((actionId) => {
			if (typeof ModuleActions[actionId] === "function") return;

			const canUseRule =
				userActionsEnabled.length > 0 &&
				userActionsEnabled.includes(ModuleActionIds[actionId]);

			ModuleActions[actionId].disabled = !canUseRule;
		});
	}
};
