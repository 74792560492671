import React from "react";

import "./shared/TabStyles.scss";

/**
 * Contract for the Properties of the Tab component
 */
interface ITabProps {
	id: string;
	title: string;
	className?: undefined | string;
	children?: undefined | React.ReactNode;
	render?: undefined | ((tab: ITabProps, index: number) => JSX.Element);
}

/**
 * Organizer component for tabs listing,
 * Must be used with all compulsory props.
 *
 * @returns JSX.Element
 */
function Tab({ children, id, className, ...rest }: ITabProps): JSX.Element {
	return !children ? null : (
		<div id={id} {...rest} className="tab-item" tabIndex={0}>
			{children}
		</div>
	);
}

export { Tab, ITabProps };
