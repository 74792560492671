const _locale = "pt-BR";
const _currency = {
	code: "BRL",
	symbol: "R$"
};
const _localeOptions: Intl.NumberFormatOptions = {
	style: "currency",
	currency: _currency.code
};

/**
 * Class for Formatters of the system, formatting data from each method.
 */
export class FormatServicesFixture {
	/**
	 * Formats a number to a currency value.
	 *
	 * @param value The number to be formatted.
	 * @param localeOpts [Optional] The locale options to be used.
	 * @returns The formatted currency value.
	 */
	currency(
		value: number,
		localeOpts: Intl.NumberFormatOptions = undefined
	): string {
		return value.toLocaleString(_locale, localeOpts);
	}
	/**
	 * Formats a value and adds a currency prefix to it.
	 *
	 * @param value The value to be formatted.
	 * @returns The formatted value with a currency prefix.
	 */
	currencyPrefix(value: number): string {
		return `${this.currency(value, _localeOptions)}`;
	}
	/**
	 * Formats a Date into a locale string.
	 *
	 * @param date The date to be formatted.
	 * @returns The formatted date.
	 */
	date(
		date: Date,
		localeOpts: Intl.DateTimeFormatOptions = undefined
	): string {
		const dateObject = new Date(date ?? Date.now());
		return dateObject.toLocaleDateString(_locale, localeOpts);
	}
	/**
	 * Formats a Date into a locale Date/time string.
	 *
	 * @param dateTime The date to be formatted.
	 * @returns The formatted date.
	 */
	dateTime(
		dateTime: Date,
		localeOpts: Intl.DateTimeFormatOptions = undefined
	): string {
		const dateObject = new Date(dateTime ?? Date.now());
		return `${this.date(dateObject, localeOpts)} ${this.time(
			dateObject,
			localeOpts
		)}`;
	}
	/**
	 * Formats a Date into a locale time string.
	 *
	 * @param dateTime The date to be formatted.
	 * @returns The formatted date.
	 */
	time(
		dateTime: Date,
		localeOpts: Intl.DateTimeFormatOptions = undefined
	): string {
		return (
			dateTime instanceof Date ? dateTime : new Date(dateTime)
		).toLocaleTimeString(_locale, localeOpts);
	}
	/**
	 * Formats a string|number to a decimal point-based locale string.
	 *
	 * @param value The value to be formatted.
	 * @param decimalPoints The number of decimal points to be formatted.
	 * @returns The formatted value.
	 */
	float(value: string | number, decimalPoints: number = 2): string {
		return !value
			? parseFloat("0").toFixed(decimalPoints)
			: typeof value === "number"
			? value.toFixed(decimalPoints)
			: parseFloat(value).toFixed(decimalPoints);
	}
	/**
	 * Formats a string|number to an ISO Time string.
	 *
	 * @param dateTime The date to be formatted.
	 * @returns The formatted date.
	 */
	timeISO(dateTime: string | Date): string {
		const isoDate =
			dateTime instanceof Date
				? dateTime?.toISOString()
				: new Date(dateTime).toISOString();
		const isoTime = isoDate.split("T")[1];

		return isoTime;
	}
}

/**
 * Singleton instance of the FormatService exported.
 */
export const formatServices = new FormatServicesFixture();
