import { IFirebaseItem } from "interfaces/index";
import {
	onChangeField,
	onChangeSelect,
	onChangeArrayField
} from "components/shared/handler";
import { IOption } from "components/shared/dropdown";
import { useState } from "react";

/**
 * Suppliers page component.
 */
interface IPageKitHook<IItemType extends IFirebaseItem> {
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	newItem: IItemType;
	setNewItem: React.Dispatch<React.SetStateAction<IItemType>>;
	onChange: (
		fieldName: string
	) => (
		event: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement
		>
	) => void;
	onChangeSelect: (
		fieldName: string
	) => (
		event: React.ChangeEvent<HTMLSelectElement>,
		option: IOption | IOption[]
	) => void;
	onChangeArrayField: (
		fieldName: string
	) => (
		event: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLTextAreaElement
		>
	) => void;
	onPopulate: (item: IItemType) => void;
}

/**
 * Context Page component grouped actions and state management.
 *
 * @returns The PageKit hook.
 */
function usePageKit<
	IItemType extends IFirebaseItem
>(): IPageKitHook<IItemType> {
	const [modalOpen, setModalOpen] = useState(false);
	const [newItem, setNewItem] = useState<IItemType>({} as IItemType);

	/**
	 * Handles the Change event for a field.
	 *
	 * @param fieldName
	 * @returns
	 */
	function handleChangeField(fieldName: string): (event: any) => void {
		return onChangeField(fieldName, setNewItem);
	}

	/**
	 * Handles the Change event for a Select component.
	 *
	 * @param fieldName
	 * @returns
	 */
	function handleChangeSelect(
		fieldName: string
	): (event: any, option: IOption) => void {
		return onChangeSelect(fieldName, setNewItem);
	}

	/**
	 * Handles the Change event for an Array of strings.
	 *
	 * @param fieldName
	 * @returns
	 */
	function handleChangeArrayField(fieldName: string): (event: any) => void {
		return onChangeArrayField(fieldName, setNewItem);
	}

	return {
		modalOpen,
		setModalOpen,
		newItem,
		setNewItem,
		onChange: handleChangeField,
		onChangeSelect: handleChangeSelect,
		onChangeArrayField: handleChangeArrayField,
		onPopulate: (item: IItemType) => setNewItem(item)
	};
}

export { usePageKit, IPageKitHook };
