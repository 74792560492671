import { Routes } from "components/router/Routes";
import { ComponentPaths } from "components/router/ComponentPaths";
import { LazyLoad } from "components/router/LazyLoad";
import { RouteObject } from "react-router-dom";
import { AppModuleNames } from "./AppModules";
import { AuthLayerEnabledModule } from "./AuthLayerEnabled.module";

/**
 * The Dashboard module routes.
 *
 * @returns The module routes.
 */
export const DashboardModule: () => RouteObject[] = () => {
	return AuthLayerEnabledModule(AppModuleNames.dashboard, [
		{
			path: Routes.DashboardGraphDetail(),
			element: LazyLoad(ComponentPaths.DashboardGraphDetail)
		}
	]);
};
