import { FirebaseFunctionsService } from "services/firebase/functions";

/**
 * Used to Create an User Account, after customer validation has occurred.
 */
class CreateUserAccountService extends FirebaseFunctionsService {
	constructor() {
		super("createUserAccount");
	}
}

export { CreateUserAccountService };
