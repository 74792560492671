import React from "react";
import { appStateService } from "App";

/**
 * Interface for the EnabledDisabledSwitch component.
 */
export interface IEnableDisableSwitchProps {
	id: string;
	enabled: boolean;
	service: (
		id: string,
		enabled: boolean,
		callback?: undefined | (() => void)
	) => void;
}

/**
 * Re-usable Enabled/Disabled Switch Component configuration.
 *
 * @param item The item to render inside the column
 * @returns The rendered column
 */
export const EnabledDisabledSwitch: React.FC<
	IEnableDisableSwitchProps
> = (props: {
	id: string;
	enabled: boolean;
	service: (
		id: string,
		enabled: boolean,
		callback?: undefined | (() => void)
	) => void;
}) => {
	const texts = {
		enabled: "Ativo",
		disabled: "Inativo",
		yes: "Sim",
		no: "Não",
		deactivate: "Desativar",
		activate: "Ativar",
		toggleThisItem: "{0} este item?",
		toggleThisItemDescription: `Ao confirmar esta ação, este item será ${
			props.enabled ? "desativado" : "ativado"
		}`
	};
	// const categoryService = useActiveCompanyWrapper(() =>
	// 	appStateService.company.category.get()
	// );
	const handleToggleEnabled = async () => {
		appStateService.appManager.showConfirm(
			texts.toggleThisItem.replace(
				"{0}",
				`${props.enabled ? texts.deactivate : texts.activate}`
			),
			texts.toggleThisItemDescription,
			async () => {
				if (typeof props?.service === "function") {
					// toggles the enabled state
					props.service(props.id, !props.enabled);
				}
			}
		);
	};

	return (
		<span
			className={"label " + (props.enabled ? "success" : "none")}
			onClick={handleToggleEnabled}
			role="button"
			title={props.enabled ? texts.deactivate : texts.activate}
		>
			{props?.enabled ? texts.enabled : texts.disabled}
		</span>
	);
};
