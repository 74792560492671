import { MessageModalType } from "enums";

/**
 * The Toast object definition
 */
export interface IToast {
	message: string;
	type: MessageModalType;
}

/**
 * The Toast slice definition
 */
export interface IToastSlice {
	config: IToast;
	open: boolean;
}

/**
 *
 * The initial state of the Toast slice
 */
export const toastInitialState: IToastSlice = {
	config: null,
	open: false
};
