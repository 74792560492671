import { ModuleActionIds } from "../actions";
import { AppModuleNames } from "../AppModules";
import { IAppProfile } from "./IAppProfile";

/**
 * The application admin profile config.
 * All actions allowed.
 */
export const AppAdminProfile: IAppProfile = {
	appModules: [
		AppModuleNames.admin,
		AppModuleNames.basic,
		AppModuleNames.brands,
		AppModuleNames.dashboard,
		AppModuleNames.orderExternal,
		AppModuleNames.orderManagement,
		AppModuleNames.orderProduction,
		AppModuleNames.stockManagement,
		AppModuleNames.storeFront,
		AppModuleNames.supplierManagement,
		AppModuleNames.tableService
	],
	moduleActions: [
		ModuleActionIds.brandsListView,
		ModuleActionIds.orderProductionModeView
	]
};
