import React from "react";
import { RouteObject } from "react-router-dom";
import { AppAuthentication } from "components/shared/layer/AppAuthentication";

/**
 * Wraps the passed pages with the authentication layer.
 *
 * @param pages The pages to wrap
 * @returns The wrapped pages as a collection of RouteObject
 */
export function AuthenticationLayer(pages: RouteObject[]): RouteObject[] {
	return (
		pages?.map((route) => {
			return {
				...route,
				element: <AppAuthentication>{route.element}</AppAuthentication>
			};
		}) ?? []
	);
}
