import { generatePath } from "react-router-dom";

/**
 * An interface to define the routes of the application.
 */
export interface IRoutes {
	All: string;
	BeeAIChat: (params?: undefined | { id: string }) => string;
	Landing: string;
	Home: string;
	Error404: string;
	Login: string;
	LoginPasswdRecovery: string;
	LandingMails: string;
	ExternalSite: string;
	SignUp: string;
	OnBoarding: string;
	Profile: string;
	CategoriesList: string;
	BrandsList: string;
	ProductsList: string;
	ProductView: (params?: undefined | { id: string }) => string;
	ProductStockList: string;
	ProductStockEdit: (params?: undefined | { id: string }) => string;
	// ProductStockView: (params?: undefined | { id: string }) => string;
	SuppliersList: string;
	ClientsList: string;
	ClientTables: string;
	SellOrderForm: string;
	OrderManagement: string;
	ClientOrderList: string;
	ClientOrder: (params?: undefined | { orderId: string }) => string;
	OrderProductionPage: string;
	OrderProductionCarouselPage: string;
	ExternalOrderForm: string;
	StoreHome: (params?: undefined | { id: string }) => string;
	StoreItemView: (
		params?: undefined | { id: string; itemId: string }
	) => string;
	StockMovementsList: string;
	CompanyLinkList: string;
	DashboardGraphDetail: (params?: undefined | { id: string }) => string;
	CompanyAdminList: string;
	MailerAdminList: string;
}

/**
 * Centered Route table, respecting an alphabetical order.
 */
export const Routes: IRoutes = {
	All: "*",
	// BeeAIChat: "/bee-ai/chat",
	BeeAIChat: (params = undefined) =>
		!params ? "/bee-ai/chat/:id" : generatePath("/bee-ai/chat/:id", params),
	Landing: "/landing",
	Home: "/home",
	Error404: "/e404",
	Login: "/login",
	LoginPasswdRecovery: "/login/passwd-recovery",
	LandingMails: "/landing-page/mail-list",
	ExternalSite: "/site",
	SignUp: "/sign-up",
	OnBoarding: "/on-boarding/:customerId",
	// TODO: Create pages below
	Profile: "/profile",
	CategoriesList: "/categories/list",
	BrandsList: "/brands/list",
	ProductsList: "/products/list",
	ProductView: (params = undefined) =>
		!params ? "/product/:id" : generatePath("/product/:id", params),
	ProductStockList: "/stock/list",
	ProductStockEdit: (params = undefined) =>
		!params
			? "/stock/list/edit/:id"
			: generatePath("/stock/list/edit/:id", params),
	// ProductStockView: (params = undefined) =>
	// 	!params ? "/stock/:id" : generatePath("/stock/:id", params),
	SuppliersList: "/suppliers/list",
	ClientsList: "/clients/list",
	ClientTables: "/client-tables",
	SellOrderForm: "/sell-order/form/new",
	OrderManagement: "/order-management",
	ClientOrderList: "/orders/client",
	ClientOrder: (params = undefined) =>
		!params
			? "/orders/client/:orderId"
			: generatePath("/orders/client/:orderId", params),
	OrderProductionPage: "/orders/production",
	OrderProductionCarouselPage: "/orders/production-carousel",
	ExternalOrderForm: "/order/new",
	StoreHome: (params = undefined) =>
		!params ? "/s/:id" : generatePath("/s/:id", params),
	StoreItemView: (params = undefined) =>
		!params ? "/s/:id/:itemId" : generatePath("/s/:id/:itemId", params),
	StockMovementsList: "/stock/movements",
	CompanyLinkList: "/company/links",
	//"/dashboard/graph/:id"
	DashboardGraphDetail: (params = undefined) =>
		!params
			? "/dashboard/graph/:id"
			: generatePath("/dashboard/graph/:id", params),
	CompanyAdminList: "/company/admin-list",
	MailerAdminList: "/mail/list/admin"
};
