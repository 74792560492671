import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dashboardGraphDataInitialState } from "models/index";

/**
 * Dashboard Graph Data Redux slice.
 */
export const dashboardGraphDataSlice = createSlice({
	name: "dashboardGraphData",
	initialState: dashboardGraphDataInitialState,
	reducers: {
		setActiveChart: (
			state,
			action: PayloadAction<{ chartId: string; columns: any[] }>
		) => {
			state.activeChartConfig = {
				chartId: action.payload.chartId,
				columns: action.payload.columns
			};
		},
		setColumnsConfigById: (
			state,
			action: PayloadAction<{ graphId: string; config: any[] }>
		) => {
			state.chartColumnsById = {
				...state.chartColumnsById,
				[action.payload.graphId]: action.payload.config
			};
		},
		setDataById: (
			state,
			action: PayloadAction<{ graphId: string; data: any[] }>
		) => {
			state.chartDataById = {
				...state.chartDataById,
				[action.payload.graphId]: action.payload.data
			};
		},
		setLoadedById: (
			state,
			action: PayloadAction<{ graphId: string; loaded: boolean }>
		) => {
			state.loadedById = {
				...state.loadedById,
				[action.payload.graphId]: action.payload.loaded
			};
		},
		setLoadingById: (
			state,
			action: PayloadAction<{ graphId: string; loading: boolean }>
		) => {
			state.loadingById = {
				...state.loadingById,
				[action.payload.graphId]: action.payload.loading
			};
		},
		clearById: (state, action: PayloadAction<string>) => {
			// delete state.chartColumnsById[action.payload];
			// delete state.chartDataById[action.payload];
			// delete state.loadedById[action.payload];
			// delete state.loadingById[action.payload];
			state.chartColumnsById[action.payload] = null;
			state.chartDataById[action.payload] = null;
			state.loadedById[action.payload] = null;
			state.loadingById[action.payload] = null;
		}
	}
});

export const { reducer, actions } = dashboardGraphDataSlice;

export default reducer;
