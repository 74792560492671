import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";
import { IOption } from "components/shared/dropdown";

import { IAutoMailEntry } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Mailer Entries.
 */
export const MailerSelectors = {
	getMails: (state: RootState) => state.mailer.list,
	getMailsDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.mailer.list?.map((mailEntry: IAutoMailEntry) => ({
				text: mailEntry.message.subject,
				key: mailEntry.id
			})),
		(list: IDropdownOption<IOption>[]) => list
	),
	getMailById: (state: RootState, id: string) =>
		state.mailer.list.find(
			(mailEntry: IAutoMailEntry) => mailEntry.id === id
		),
	getMailsLoading: (state: RootState) => state.mailer.loading,
	getMailsQueried: (state: RootState) => state.mailer.queried,
	getIsEditing: (state: RootState) => state.mailer.editing,
	getMailsEditing: (state: RootState) => state.mailer.editingList
	// getMailsError: (state: RootState) => state.mailer.error
};
