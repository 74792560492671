export const dictionary = {
	"app.authentication.request.message": "Por favor, autentique-se.",
	"app.name": "BeeBase App",
	"app.title": "BeeBase",
	"app.description":
		"BeeBase é uma empresa Brasileira de Experiências para a cabeça.",
	"app.welcome": "Bem-vindo à BeeBase!",
	"app.bar.title": "BeeBase Admin",
	"app.bar.button.logoff": "Sair",
	"app.bar.button.logoff.confirm": "Tem certeza que deseja sair?",
	"global.dropdown.defaultItem.placeholder": "<Nenhum>",
	"global.date.months.short.0": "Jan",
	"global.date.months.short.1": "Fev",
	"global.date.months.short.2": "Mar",
	"global.date.months.short.3": "Abr",
	"global.date.months.short.4": "Mai",
	"global.date.months.short.5": "Jun",
	"global.date.months.short.6": "Jul",
	"global.date.months.short.7": "Ago",
	"global.date.months.short.8": "Set",
	"global.date.months.short.9": "Out",
	"global.date.months.short.10": "Nov",
	"global.date.months.short.11": "Dez",
	"global.loading": "Carregando...",
	"global.logout": "Sair",
	"global.login": "Entrar",
	"global.list.action.add": "Adicionar",
	"global.list.action.duplicate": "Duplicar",
	"global.list.action.edit": "Editar",
	"global.list.action.save": "Gravar",
	"global.list.action.cancel": "Cancelar",
	"global.list.action.delete": "Remover",
	"global.list.empty": "Nenhum item para exibir.",
	"global.list.count.empty": "Nenhum item",
	"global.list.count.single": "1 item",
	"global.list.count.plural": "${count} itens",
	"global.list.filter.placeholder": "Para filtrar, escreva aqui..",
	"global.userProfile.button.profile": "Configurações",
	"global.userProfile.button.signOut": "Sair",
	"list.categories.name": "Categorias",
	"list.products.name": "Produtos",
	"list.brands.name": "Marcas",
	"page.home.title": "BeeBase Admin",
	"page.home.welcome": "Bem-vindo, ${username}.",
	"page.home.dashboard.title": "Infográficos da Empresa",
	"page.login.title": "BeeBase Admin",
	"page.login.photoCredit": "Foto by ${author}",
	"page.login.form.title": "Login",
	"page.login.form.email": "E-mail",
	"page.login.form.empty.mail": "Por favor, informe o e-mail.",
	"page.login.form.password": "Senha",
	"page.login.form.passwordResetEmailSent": "E-mail de recuperação enviado.",
	"page.login.form.resetPassword": "Esqueci minha senha",
	"page.login.form.submit": "Entrar",
	"page.login.form.submit.passwordResetEmail": "Enviar e-mail de recuperação",
	"page.login.form.submit.error.invalidMail":
		"E-mail inválido. Por favor, verifique-o.",
	"page.login.form.submit.error.mailNotFound":
		"E-mail não encontrado. Por favor, verifique-o.",
	"page.login.form.submit.error":
		"Usuário e/ou senha inválido(s). Verifique e tente novamente.",
	"page.login.form.submit.error.offline":
		"Você está offline. Por favor, reconecte-se, então tente novamente.",
	"page.categoriesList.title": "Categorias",
	"page.categoriesList.onCompleteNewItemText":
		"A categoria ${categoryName} foi criada",
	"page.categoriesList.onCompleteUpdateItemText":
		"A categoria ${categoryName} foi atualizada",
	"page.categoriesList.onDeleteItemText":
		"A categoria ${categoryName} foi removida",
	"page.categoriesList.onSaveErrorText":
		"Falha ao salvar a categoria ${categoryName}. Por favor, tente novamente.",
	"page.categoriesList.onLoadErrorText":
		"Falha ao carregar as categorias. Por favor, atualize a página.",
	"page.categoriesList.onCancelText":
		"As alterações na categoria foram canceladas.",
	"page.categoriesList.onDuplicateNameText":
		"Já existe uma categoria com o nome ${categoryName}.",
	"page.clientOrderList.title": "Pedidos (Clientes)",
	"page.clientsList.title": "Clientes",
	"page.clientsList.onCompleteNewItemText":
		"O cliente ${clientName} foi criado",
	"page.clientsList.onCompleteUpdateItemText":
		"O cliente ${clientName} foi atualizado",
	"page.clientsList.onDeleteItemText": "O cliente ${clientName} foi removido",
	"page.clientsList.onSaveErrorText":
		"Falha ao salvar o cliente ${clientName}. Por favor, tente novamente.",
	"page.clientsList.onLoadErrorText":
		"Falha ao carregar os clientes. Por favor, atualize a página.",
	"page.clientsList.onCancelText":
		"As alterações no cliente foram canceladas.",
	"page.clientsList.onDuplicateDocumentText":
		"Já existe um cliente com o documento ${documentId}.",
	"page.productsList.title": "Produtos",
	"page.productsList.action.duplicate.label": "Cópia de",
	"page.productsList.action.duplicate.confirm.title": "Duplicar Produto",
	"page.productsList.action.duplicate.confirm.message":
		"Você tem certeza que deseja duplicar este produto?",
	"page.suppliersList.title": "Fornecedores",
	"page.brandsList.title": "Marcas",
	"page.landingMails.title": "E-mails (Página Landing)",
	"page.stockList.title": "Estoque de Produtos",
	"page.home.chat-shortcut": "Iniciar Chat com a Bee AI",
	"page.orderList.items.count": "${count} item(ns).",
	"page.order.internal.pageTitle.new": "Criar Pedido",
	"page.order.internal.pageTitle.edit": "Visualizar Pedido",
	"page.order.management.list.empty": "Nenhum pedido aqui",
	"page.order.management.order.item.action.idClick":
		"Ative para navegar aos detalhes do pedido",
	"abbrev.unit": "un",
	"general.notAvailable": "N/D",
	"order.list.status.new":
		"Este pedido foi recém criado. Aguardando ação do cliente.",
	"order.list.status.needsPayment":
		"Este pedido está aguardando pagamento pelo cliente.",
	"order.list.status.confirmed":
		"O pagamento deste pedido foi confirmado. Processando movimentações de pagamento e estoque.",
	"order.list.status.pending":
		"Este pedido foi pago, confirmado, portanto está aguardando movimentação interna.",
	"order.list.status.inProgress":
		"Este pedido está em andamento, mas ainda não foi empacotado para envio.",
	"order.list.status.packaging":
		"Este pedido está sendo empacotado para envio.",
	"order.list.status.readyToDeliver":
		"Este pedido está pronto para entrega, portanto, aguarda um entregador.",
	"order.list.status.inDelivery":
		"Este pedido está sendo entregue por ${deliveryPersonName}.",
	"order.list.status.delivered":
		"Este pedido foi entregue com sucesso. Aguarda confirmação pelo cliente.",
	"order.list.status.completed": "Este pedido foi finalizado com sucesso.",
	"global.modal.message.action.cancel": "Cancelar",
	"global.modal.message.action.confirm": "Ok",
	"global.modal.message.default.title": "Aviso",
	"global.button.backToHome": "Voltar para a Página Inicial",
	"global.button.confirm": "Confirmar",
	"global.button.cancel": "Cancelar",
	"global.button.save": "Salvar",
	"global.button.close": "Fechar",
	"global.confirmCloseWithUnsavedChanges.title": "Confirmação",
	"global.confirmCloseWithUnsavedChanges.description":
		"Tem certeza que deseja sair? Há alterações não salvas.",
	"page.order.internal.checkout.criticalError":
		"Cancelado: Erro crítico ao criar o checkout",
	"page.order.internal.checkout.success": "Pedido criado com sucesso",
	"page.order.internal.modalTitle.new": "Novo Pedido",
	"page.order.internal.modalTitle.edit": "Pedido ${orderId}",
	"global.you": "Você",
	"page.signup.pageTitle": "Vamos começar",
	"page.signup.description": "É ótimo ter você aqui na BeeBase!",
	"page.signup.formRedirecting": "Redirecionando para a página de login...",
	"dashboard.groups.title": "Infográficos da empresa",
	"page.stockMovementList.title": "Movimentações de Estoque",
	"page.clientTables.title": "Mesas do Estabelecimento",
	"order.status.new": "Novo",
	"order.status.needsPayment": "Necessita Pagamento",
	"order.status.confirmed": "Confirmado",
	"order.status.pending": "Pendente",
	"order.status.inProgress": "Em Andamento",
	"order.status.packaging": "Empacotando",
	"order.status.tableServed": "Servido a mesa",
	"order.status.readyToDeliver": "Pronto para Entregar",
	"order.status.inDelivery": "Em Entrega",
	"order.status.delivered": "Entregue",
	"order.status.completed": "Concluído",
	"order.status.cancelled": "Cancelado",
	"order.status.rejected": "Rejeitado",
	"order.status.error": "Erro",
	"chart.trend.label": "Tendência",
	"chart.newClients.label": "Qtd. novos clientes",
	"chart.trend.initialColor": "rgba(255, 206, 86, 1)",
	"chart.trend.successColor": "rgba(75, 192, 192, 1)",
	"chart.trend.negativeColor": "rgba(255, 99, 132, 1)",
	"chart.trend.noChangeColor": "rgba(201, 203, 207, 1)",
	"userProfileForm.formTitle": "Meu Perfil",
	"userProfileForm.sectionTitle": "Informações Pessoais",
	"userProfileForm.propTitles.userName": "Nome",
	"userProfileForm.propTitles.userDisplayName": "Nome Preferido",
	"userProfileForm.propTitles.userMail": "E-mail",
	"userProfileForm.propTitles.isAdmin": "Administrador",
	"userProfileForm.ariaLabels.editUserName": "Editar Nome do Usuário",
	"userProfileForm.ariaLabels.editDisplayName": "Editar Nome Preferido",
	"product.enabled": "Ativo",
	"product.enabled.true": "Sim",
	"product.enabled.false": "Não",
	"product.enabled.hint":
		"Desativar este produto o tornará invisível para os clientes, em suas lojas.",
	"product.production": "Produzido",
	"product.production.true": "Sim",
	"product.production.false": "Não",
	"product.production.hint":
		"Se este produto precisa de produção, irá acionar comandos no painel de produção.",
	"product.variations.title": "Variações",
	"product.variations.add": "Adicionar Variação",
	"product.variations.empty": "Nenhuma variação cadastrada.",
	"product.variations.hint":
		"Variações são diferentes versões do mesmo produto.",
	"product.variations.placeholder": "Nome desta Variação",
	"product.additionalItems.title": "Itens Adicionais",
	"product.additionalItems.add": "Adicionar Item Adicional",
	"product.additionalItems.empty": "Nenhum item adicional cadastrado.",
	"product.additionalItems.hint":
		"Itens adicionais são produtos que podem ser vendidos junto com este.",
	"product.additionalItems.placeholder": "Nome do Adicional",
	"page.companyLinksList.title": "Links da Empresa",
	"page.companyLinksList.onCompleteNewItemText":
		"O link ${linkName} foi criado",
	"page.companyLinksList.onCompleteUpdateItemText":
		"O link ${linkName} foi atualizado",
	"page.companyLinksList.onDeleteItemText": "O link ${linkName} foi removido",
	"page.companyLinksList.onSaveErrorText":
		"Falha ao salvar o link ${linkName}. Por favor, tente novamente.",
	"page.companyLinksList.onLoadErrorText":
		"Falha ao carregar os links. Por favor, atualize a página.",
	"page.companyLinksList.onCancelText":
		"As alterações no link foram canceladas.",
	"page.companyLinksList.onDuplicateNameText":
		"Já existe um link com o nome ${linkName}.",
	"productDetails.title": "Detalhes",
	"productDetails.radio.select": "Consultar",
	"productDetails.radio.add": "Adicionar",
	"productDetails.label.id": "ID (Sistema)",
	"productDetails.label.name": "Nome (Produto)",
	"productDetails.label.sku": "SKU (Código único)",
	"productDetails.label.category": "Categoria",
	"productDetails.label.brand": "Marca",
	"productDetails.label.description": "Descrição",
	"productDetails.label.barCode": "Código de Barras",
	"productDetails.input.search": "Pesquisar",
	"productDetails.input.add": "Adicionar",
	"productDetails.actions.generateDescription":
		"Gerar descrição usando IA (Bee AI)",
	"productDetails.placeholders.name":
		"Exemplo: Camiseta Branca Tamanho M, Calça Jeans Azul Tamanho 40.",
	"list.clientTables.busy": "Ocupada",
	"list.clientTables.free": "Livre",
	"productPricingSection.title": "Preços",
	"productPricingSection.costLabel": "Custo de Aquisição (R$)",
	"productPricingSection.transportPercentLabel": "% Transporte",
	"productPricingSection.creditCardMarginLabel": "Margem Cartão de Crédito",
	"productPricingSection.variableCostMarginLabel": "% Custos Variáveis",
	"productPricingSection.wagePercentLabel": "% Lucro",
	"productPricingSection.minimumPriceLabel": "Preço Mínimo (R$)",
	"productPricingSection.salePriceLabel": "Preço de Venda (R$)",
	"productPricingSection.searchAveragePriceButton":
		"Pesquisar preço médio de mercado (Bee AI)",
	"productPricingSection.nameRequiredWarning":
		"É necessário informar o nome do produto para utilizar este recurso.",
	"paymentType.creditCard": "Cartão de Crédito",
	"paymentType.debitCard": "Cartão de Débito",
	"paymentType.cash": "Dinheiro",
	"paymentType.pix": "PIX",
	"paymentType.googlePay": "Google Pay",
	"paymentType.picPay": "PicPay",
	"paymentType.all": "Todos",
	"page.companiesList.title": "Empresas (Admin)",
	"companyAdmin.form.title.new": "Cadastro de Empresa",
	"companyAdmin.form.title.edit": "Empresa: ${companyName}",
	"companyAdmin.form.id": "ID (Sistema)",
	"companyAdmin.form.name": "Nome (razão social)",
	"companyAdmin.form.name.placeholder": "Nome da Empresa",
	"companyAdmin.form.name.required": "O Nome da empresa é obrigatório.",
	"companyAdmin.form.companyProfile": "Perfil de Acesso",
	"companyAdmin.form.companyProfile.adminProfileFull": "Perfil Admin",
	"companyAdmin.form.companyProfile.appNone": "Sem Plano",
	"companyAdmin.form.companyProfile.appBasicPlan": "Plano App Basic",
	"companyAdmin.form.companyProfile.appMasterPlan": "Plano App Master",
	"companyAdmin.form.active": "Ativa",
	"companyAdmin.form.active.true": "Sim",
	"companyAdmin.form.active.false": "Não",
	"companyAdmin.form.active.hint":
		"Desativar esta empresa a tornará inacessível para todos os usuários atribuídos.",
	"companyAdmin.form.ownerId": "ID do Proprietário",
	"companyAdmin.form.foundedOn": "Fundada em",
	"companyService.setEnabledById.statusTrue": "Ativada",
	"companyService.setEnabledById.statusFalse": "Desativada",
	"companyService.setEnabledById.successMessage":
		"Empresa ${companyName} foi ${status} com sucesso!",
	"userPreferencesSideGroup.title": "Configurações",
	"userPreferencesSideGroup.tabTitles.userProfileForm": "Meu Perfil",
	"userPreferencesSideGroup.tabTitles.companyForm": "Empresa",
	"userCompanyForm.labels.contactMail": "E-mail",
	"userCompanyForm.labels.foundedOn": "Fundado em",
	"userCompanyForm.labels.companyName": "Nome (Razão Social)",
	"userCompanyForm.labels.contactPhone": "Telefone",
	"userCompanyForm.labels.contactWhatsApp": "WhatsApp",
	"userCompanyForm.placeholders.foundedOn": "Data de Fundação",
	"userCompanyForm.placeholders.companyName": "Nome da Empresa",
	"userCompanyForm.placeholders.contactMail": "E-mail da Empresa",
	"userCompanyForm.placeholders.contactPhone": "Telefone da Empresa",
	"userCompanyForm.placeholders.contactWhatsApp": "WhatsApp da Empresa",
	"page.mailerAdminList.title": "E-mails e Administração",
	"automailer.form.status.all": "Todos",
	"automailer.form.status.pending": "Pendente",
	"automailer.form.status.processing": "Processando",
	"automailer.form.status.success": "Enviado",
	"automailer.form.status.error": "Erro",
	"page.signup.steps.labels.name": "Como você se chama?",
	"page.signup.steps.labels.email": "Qual seu E-mail preferido para contato?",
	"page.signup.steps.labels.birthday": "Quando você nasceu?",
	"page.signup.steps.labels.paymentMethod":
		"Como você prefere efetuar seu pagamento?",
	"page.signup.steps.labels.confirm":
		"Certo! Por favor, confira se seus dados estão corretos:",
	"page.signup.steps.labels.processing":
		"Aguarde um momentinho, estamos preparando tudo para você...",
	"page.signup.steps.labels.complete":
		"Pronto! Verifique seu e-mail para concluir o seu cadastro.",
	"page.signup.steps.review.name": "Nome: ${name}",
	"page.signup.steps.review.email": "E-mail: ${email}",
	"page.signup.steps.review.birthday": "Data de Nascimento: ${birthday}",
	"page.signup.steps.review.paymentMethod": "Método de Pagamento: ${method}",
	"paymentType.label.boleto": "Boleto",
	"paymentType.label.creditCard": "Cartão de Crédito",
	"paymentType.label.debitCard": "Cartão de Débito",
	"paymentType.label.pix": "PIX (Pagamento Instantâneo)",
	"page.brandsList.onCompleteNewItemText": "A marca ${brandName} foi criada",
	"page.brandsList.onCompleteUpdateItemText":
		"A marca ${brandName} foi atualizada",
	"page.brandsList.onDeleteItemText": "A marca ${brandName} foi removida",
	"page.brandsList.onSaveErrorText":
		"Falha ao salvar a marca ${brandName}. Por favor, tente novamente.",
	"page.brandsList.onLoadErrorText":
		"Falha ao carregar as marcas. Por favor, atualize a página.",
	"page.brandsList.onCancelText": "As alterações na marca foram canceladas.",
	"page.brandsList.onDuplicateNameText":
		"Já existe uma marca com o nome ${brandName}.",
	"page.suppliersList.onCompleteNewItemText":
		"O fornecedor ${supplierName} foi criado",
	"page.suppliersList.onCompleteUpdateItemText":
		"O fornecedor ${supplierName} foi atualizado",
	"page.suppliersList.onDeleteItemText":
		"O fornecedor ${supplierName} foi removido",
	"page.suppliersList.onSaveErrorText":
		"Falha ao salvar o fornecedor ${supplierName}. Por favor, tente novamente.",
	"page.suppliersList.onLoadErrorText":
		"Falha ao carregar os fornecedores. Por favor, atualize a página.",
	"page.suppliersList.onCancelText":
		"As alterações no fornecedor foram canceladas.",
	"page.suppliersList.onDuplicateNameText":
		"Já existe um fornecedor com o nome ${supplierName}.",
	"page.clientTables.title": "Mesas do Estabelecimento",
	"page.clientTables.onCompleteNewItemText": "A mesa ${tableName} foi criada",
	"page.clientTables.onCompleteUpdateItemText":
		"A mesa ${tableName} foi atualizada",
	"page.clientTables.onDeleteItemText": "A mesa ${tableName} foi removida",
	"page.clientTables.onSaveErrorText":
		"Falha ao salvar a mesa ${tableName}. Por favor, tente novamente.",
	"page.clientTables.onLoadErrorText":
		"Falha ao carregar as mesas. Por favor, atualize a página.",
	"page.clientTables.onCancelText": "As alterações na mesa foram canceladas.",
	"page.clientTables.onDuplicateNameText":
		"Já existe uma mesa com o nome ${tableName}.",
	"page.companiesList.title": "Empresas (Admin)",
	"page.companiesList.onCompleteNewItemText":
		"A empresa ${companyName} foi criada",
	"page.companiesList.onCompleteUpdateItemText":
		"A empresa ${companyName} foi atualizada",
	"page.companiesList.onDeleteItemText":
		"A empresa ${companyName} foi removida",
	"page.companiesList.onSaveErrorText":
		"Falha ao salvar a empresa ${companyName}. Por favor, tente novamente.",
	"page.companiesList.onLoadErrorText":
		"Falha ao carregar as empresas. Por favor, atualize a página.",
	"page.companiesList.onCancelText":
		"As alterações na empresa foram canceladas.",
	"page.companiesList.onDuplicateNameText":
		"Já existe uma empresa com o nome ${companyName}.",
	"page.productsList.title": "Produtos",
	"page.productsList.onCompleteNewItemText":
		"O produto ${productName} foi criado",
	"page.productsList.onCompleteUpdateItemText":
		"O produto ${productName} foi atualizado",
	"page.productsList.onDeleteItemText":
		"O produto ${productName} foi removido",
	"page.productsList.onSaveErrorText":
		"Falha ao salvar o produto ${productName}. Por favor, tente novamente.",
	"page.productsList.onLoadErrorText":
		"Falha ao carregar os produtos. Por favor, atualize a página.",
	"page.productsList.onCancelText":
		"As alterações no produto foram canceladas.",
	"page.productsList.onDuplicateNameText":
		"Já existe um produto com o nome ${productName}.",
	"page.productsList.onDuplicateSuccessText":
		"O produto ${productName} foi duplicado.",
	"page.stockList.title": "Estoque de Produtos",
	"page.stockList.onCompleteNewItemText":
		"O item de estoque ${stockName} foi criado",
	"page.stockList.onCompleteUpdateItemText":
		"O item de estoque ${stockName} foi atualizado",
	"page.stockList.onDeleteItemText":
		"O item de estoque ${stockName} foi removido",
	"page.stockList.onSaveErrorText":
		"Falha ao salvar o item de estoque ${stockName}. Por favor, tente novamente.",
	"page.stockList.onLoadErrorText":
		"Falha ao carregar os itens de estoque. Por favor, atualize a página.",
	"page.stockList.onCancelText":
		"As alterações no item de estoque foram canceladas.",
	"page.stockList.onDuplicateNameText":
		"Já existe um item de estoque com o nome ${stockName}.",
	"page.error.title": "Ops! Algo deu errado.",
	"page.order.toast.error.loadOrders":
		"Falha ao carregar os pedidos. Por favor, atualize a página.",
	"page.order.toast.error.saveOrder":
		"Falha ao salvar o pedido ${orderId}. Por favor, tente novamente.",
	"page.order.toast.success.createOrder":
		"Pedido ${orderId} foi criado com sucesso.",
	"page.order.toast.success.updateOrder":
		"Pedido ${orderId} foi atualizado com sucesso.",
	"page.order.toast.success.deleteOrder": "Pedido ${orderId} foi removido.",
	"page.order.toast.success.statusChange":
		"Status do pedido ${orderId} alterado para ${status}.",
	"page.order.toast.error.statusChange":
		"Falha ao alterar o status do pedido ${orderId}. Por favor, tente novamente.",
	"page.order.toast.success.itemStatusChange":
		"Status do item ${itemName} alterado para ${status}.",
	"page.order.toast.error.itemStatusChange":
		"Falha ao alterar o status do item ${itemName}. Por favor, tente novamente.",
	"page.order.toast.error.checkoutCreate":
		"Falha ao criar checkout para o pedido ${orderId}.",
	"page.order.toast.success.checkoutCreate":
		"Checkout criado com sucesso para o pedido ${orderId}.",
	"page.order.toast.error.inventoryMovement":
		"Falha ao atualizar o estoque para o pedido ${orderId}.",
	"page.order.toast.success.inventoryMovement":
		"Estoque atualizado com sucesso para o pedido ${orderId}.",
	"page.mailerAdminList.releaseAnnouncement.button": "Anunciar Versão",
	"page.mailerAdminList.releaseAnnouncement.title": "Anunciar Nova Versão",
	"page.mailerAdminList.releaseAnnouncement.fields.version": "Versão",
	"page.mailerAdminList.releaseAnnouncement.fields.recipients":
		"Destinatários",
	"page.mailerAdminList.releaseAnnouncement.fields.message": "Mensagem",
	"page.mailerAdminList.releaseAnnouncement.placeholders.recipients":
		"Digite os destinatários separados por vírgulas",
	"page.mailerAdminList.releaseAnnouncement.placeholders.message":
		"Digite a mensagem a ser enviada aos destinatários",
	"page.mailerAdminList.releaseAnnouncement.success":
		"Nova versão ${version} anunciada com sucesso!",
	"page.mailerAdminList.releaseAnnouncement.error":
		"Falha ao anunciar a nova versão ${version}. Por favor, tente novamente.",
	"component.companySelector.button.label": "Selecionar Empresa",
	"component.companySelector.button.ariaLabel":
		"Abrir menu de seleção de empresa",
	"component.companySelector.option.ariaLabel":
		"Selecionar ${companyName} como empresa ativa",
	"page.mailerAdminList.releaseAnnouncement.subject":
		"BeeBase v${version} - Notas da Versão",
	"page.onboarding.title": "Ativando seu Cadastro",
	"page.onboarding.description":
		"Por favor, verifique seu e-mail para continuar.",
	"page.onboarding.instructions.step0": "Digite o código recebido por e-mail",
	"page.onboarding.messages.error.invalidCode":
		"Código de verificação inválido. Por favor, tente novamente.",
	"page.onboarding.messages.error.expiredCode":
		"Este código de verificação expirou. Por favor, solicite um novo.",
	"page.onboarding.messages.error.generic":
		"Ocorreu um erro ao validar seu código. Por favor, tente novamente.",
	"page.onboarding.messages.success.validated":
		"Código validado com sucesso! Redirecionando...",
	"page.onboarding.code.label": "Digite o código recebido por e-mail",
	"page.onboarding.code.error.required": "O código é obrigatório",
	"page.onboarding.code.error.invalid": "Código inválido ou já utilizado",
	"page.onboarding.code.error.generic":
		"Ocorreu um erro ao validar seu código. Por favor, tente novamente.",
	"page.onboarding.code.success.validated":
		"Ok! Código validado com sucesso.",
	"page.onboarding.password.title": "Definir Senha",
	"page.onboarding.password.description":
		"Por favor, crie uma senha segura para sua conta",
	"page.onboarding.password.label": "Defina sua Senha de Acesso",
	"page.onboarding.password.instructions":
		"Sua senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial.",
	"page.onboarding.password.error.required": "A senha é obrigatória",
	"page.onboarding.password.error.min":
		"A senha deve ter pelo menos 8 caracteres",
	"page.onboarding.password.error.invalid":
		"A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial",
	"page.onboarding.messages.success.completed":
		"Configuração da conta concluída com sucesso!",
	"common.password.show": "Mostrar senha",
	"common.password.hide": "Ocultar senha",
	"common.password.instructions":
		"A senha deve conter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula, um número e um caractere especial (@$!%*?&)."
};
