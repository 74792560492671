import React from "react";
import { appStateService } from "App";

/**
 * Interface for the EnabledDisabledSwitch component.
 */
export interface IYesNoSwitchProps {
	id: string;
	value: boolean;
	labels: {
		yes: string;
		no: string;
		actions: {
			switchToYes: string;
			switchToNo: string;
			afterSwitchYes: string;
			afterSwitchNo: string;
		};
	};
	service: (id: string, yes: boolean, callback: () => void) => Promise<void>;
}

/**
 * Re-usable Yes/No Switch Component configuration.
 *
 * @param item The item to render inside the column
 * @returns The rendered column
 */
export const YesNoSwitch: React.FC<IYesNoSwitchProps> = (props: {
	id: string;
	value: boolean;
	labels: {
		yes: string;
		no: string;
		actions: {
			switchToYes: string;
			switchToNo: string;
			afterSwitchYes: string;
			afterSwitchNo: string;
		};
	};
	service: (id: string, value: boolean, callback: () => void) => void;
}) => {
	const texts = {
		// enabled: "Ativo",
		// disabled: "Inativo",
		yes: props?.labels?.yes ?? "Sim",
		no: props?.labels?.no ?? "Não",
		// deactivate: "Desativar",
		// activate: "Ativar",
		toggleThisItem: "{0} este item?",
		toggleThisItemDescription: `Ao confirmar esta ação, este item será ${
			props.value
				? props?.labels.actions.afterSwitchNo
				: props?.labels.actions.afterSwitchYes
		}`
	};
	// const categoryService = useActiveCompanyWrapper(() =>
	// 	appStateService.company.category.get()
	// );
	const handleToggleEnabled = async () => {
		appStateService.appManager.showConfirm(
			texts.toggleThisItem.replace(
				"{0}",
				`${
					props.value
						? props.labels.actions.switchToNo
						: props.labels.actions.switchToYes
				}`
			),
			texts.toggleThisItemDescription,
			async () => {
				if (typeof props?.service === "function") {
					props.service(props.id, !props.value, () => {});
				}
			}
		);
	};

	return (
		<span
			className={"label " + (props.value ? "success" : "none")}
			onClick={handleToggleEnabled}
			role="button"
			title={
				props.value
					? props.labels.actions.switchToNo
					: props.labels.actions.switchToYes
			}
		>
			{props?.value ? texts.yes : texts.no}
		</span>
	);
};
