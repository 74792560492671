import { Routes } from "components/router/Routes";
import { ComponentPaths } from "components/router/ComponentPaths";
import { LazyLoad } from "components/router/LazyLoad";
import { RouteObject } from "react-router-dom";
import { AuthLayerEnabledModule } from "./AuthLayerEnabled.module";
import { AppModuleNames } from "./AppModules";

/**
 * The Order Management module routes.
 *
 * @returns The module routes.
 */
export const OrderManagementModule: () => RouteObject[] = () => {
	return AuthLayerEnabledModule(AppModuleNames.orderManagement, [
		{
			path: Routes.OrderManagement,
			element: LazyLoad(ComponentPaths.OrderManagementPage)
		},
		{
			path: Routes.ClientOrderList,
			element: LazyLoad(ComponentPaths.ClientOrderList)
		},
		{
			path: Routes.ClientOrder(),
			element: LazyLoad(ComponentPaths.ClientOrder)
		}
	]);
};
