import { Loader } from "@googlemaps/js-api-loader";

const loaderAPIKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

/**
 * Class for loading
 * Google Maps API underneath.
 */
export class GoogleMapsLoader {
	private loader: Loader;
	private map: google.maps.Map;
	private latitude: string;
	private longitude: string;
	private locationBias: google.maps.LatLngLiteral;
	private markerView: google.maps.marker.AdvancedMarkerElement;

	constructor(apiKey: string) {
		this.loader = new Loader({
			apiKey: apiKey,
			version: "weekly",
			libraries: ["places"]
		});

		this.locationBias = { lat: -29.771219, lng: -51.148103 };
	}

	async load(
		elementSelector: string,
		latitude?: undefined | string,
		longitude?: undefined | string
	): Promise<any> {
		return await this.loader.importLibrary("maps").then(({ Map }) => {
			this.latitude = latitude ?? this.locationBias.lat.toString();
			this.longitude = longitude ?? this.locationBias.lng.toString();

			this.map = new Map(
				document.querySelector(elementSelector) as HTMLElement,
				{
					center: {
						lat: parseFloat(this.latitude),
						lng: parseFloat(this.longitude)
					},
					zoom: 8
				}
			);

			return this.map;
		});
	}

	async findPlaces(
		textQuery: string,
		placeType: string = "restaurant",
		onFound: null | ((places: google.maps.places.Place[]) => void) = null
	): Promise<void> {
		const mapRef = this.map;
		const { Place } = (await google.maps.importLibrary(
			"places"
		)) as google.maps.PlacesLibrary;
		const { AdvancedMarkerElement } = (await google.maps.importLibrary(
			"marker"
		)) as google.maps.MarkerLibrary;
		const request = {
			textQuery: textQuery,
			fields: ["displayName", "location", "businessStatus"],
			includedType: placeType,
			locationBias: {
				lat: this.locationBias.lat,
				lng: this.locationBias.lng
			},
			isOpenNow: true,
			language: "pt-BR",
			maxResultCount: 8,
			minRating: 3.2,
			region: "br",
			useStrictTypeFiltering: false
		};

		//@ts-ignore
		const { places } = await Place.searchByText(request);

		if (places.length) {
			const { LatLngBounds } = (await google.maps.importLibrary(
				"core"
			)) as google.maps.CoreLibrary;
			const bounds = new LatLngBounds();

			// Loop through and get all the results.
			places.forEach((place) => {
				this.markerView = new AdvancedMarkerElement({
					map: mapRef,
					position: place.location,
					title: place.displayName
				});

				bounds.extend(place.location as google.maps.LatLng);
				console.log(place);

				if (typeof onFound === "function") {
					onFound(places);
				}
			});

			mapRef.fitBounds(bounds);
		} else {
			// console.log("No results");
		}
	}
}

export const loader = new GoogleMapsLoader(loaderAPIKey);
