import React, { useState } from "react";
import { FormControls } from "../form";
import { Panel, PanelType } from "@fluentui/react";
import { useTranslateFn } from "hooks/i18n";
import { appStateService } from "App";

/**
 * Contract for the properties provided to the SidePanel component.
 */
export interface ISidePanelProps {
	title: string;
	open?: undefined | boolean;
	isFooterAtBottom?: undefined | boolean;
	type?: undefined | PanelType;
	onSubmit?: undefined | (() => void | Promise<void>);
	onCancel?: undefined | (() => void);
	children: React.ReactNode;
	submitDisabled?: undefined | boolean;
	lightDismiss?: undefined | boolean;
	isDirty?: undefined | boolean;
}

/**
 * Abstraction of the Fluent UI Panel component to create a side panel.
 *
 * @param props ISidePanelProps
 * @returns React.FC<ISidePanelProps>
 */
export const SidePanel: React.FC<ISidePanelProps> = ({
	isFooterAtBottom = true,
	lightDismiss = false,
	isDirty = undefined,
	...props
}) => {
	const translate = useTranslateFn();
	const strings = {
		cancel: "Cancel",
		save: "Save",
		confirmCloseTitle: translate(
			"global.confirmCloseWithUnsavedChanges.title"
		),
		confirmCloseDescription: translate(
			"global.confirmCloseWithUnsavedChanges.description"
		)
	};

	const handleCancel = () => {
		// If the form dirty controlling has been enabled and it is dirty
		if (undefined !== isDirty && isDirty) {
			appStateService.appManager.showConfirm(
				strings.confirmCloseTitle,
				strings.confirmCloseDescription,
				props?.onCancel ?? (() => {})
			);
		} else {
			props?.onCancel();
		}
	};

	return (
		<Panel
			className="side-panel"
			isLightDismiss={lightDismiss}
			isOpen={props?.open ?? false}
			type={props?.type ?? PanelType.medium}
			title={props.title}
			headerText={props.title}
			onDismiss={handleCancel}
			onSubmit={props.onSubmit ?? undefined}
			isFooterAtBottom={isFooterAtBottom}
			onRenderFooter={() => {
				return (
					<FormControls
						onSubmit={props?.onSubmit ?? undefined}
						onCancel={handleCancel}
						submitDisabled={
							props.submitDisabled ||
							(undefined !== isDirty && !isDirty)
						}
					/>
				);
			}}
		>
			{props.children}
		</Panel>
	);
};
