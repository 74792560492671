import React from "react";
import { ComponentType, lazy, Suspense } from "react";
import { Loader } from "components/shared/loader";

/**
 * Code sample from
 * https://codesandbox.io/p/sandbox/gracious-chaum-mjv9f5?file=%2Fsrc%2FApp.js%3A45%2C15
 */
export const LazyLoad = (componentPath: string) => {
	const loadFn: () => Promise<{ default: ComponentType<any> }> = () => {
		if (!componentPath) {
			throw new Error("Component path is required for Lazy Imports.");
		}

		return import(`components/page/${componentPath}`);
	};
	const Comp = lazy(loadFn);

	return (
		<Suspense fallback={<Loader open />}>
			<Comp />
		</Suspense>
	);
};
